<template>
<div>
    <!-- 注册时的服务条款 -->
    <div v-show="name == '普通平台' || name == '联想'">
        <el-dialog title="服务条款" :before-close="handleClose" :visible="showDialog" :close-on-click-modal="false" width="50%" center>
            <h4 class="contract-content">
                尊敬的用户，欢迎您登录本网站，本站依据下列服务条款为您提供服务
            </h4>
                <div class="contract-content">1、服务条款的变更和修改</div>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 本网站有权随时对服务条款进行修改，并且一旦发生服务条款的变动，本网站将在页面上提示修改的内容；当用户使用本网站的论坛、社区等各项服务时，应接受本网站随时提供的与该服务相关的规则或说明，并且此规则或说明均构成本网站服务条款的一部分。用户如果不同意服务条款的修改，可以主动取消已经获得的网络服务；如果用户继续享用网络服务，则视为用户已经同意本网站服务条款的修改。
                </p>
                <div class="contract-content">
                    2、服务说明
                </div>
                <p>
                (1) 本网站运用自主开发的网站系统通过国际互联网向用户提供丰富的网上资源，包括各种知识性内容、产品信息及服务、网上论坛、互动社区、信息工具等（以下简称本网站服务）。除非另有明确规定，增强或强化目前服务的任何新功能，包括新产品，均无条件地适用本网站服务条款。
                <br>
                (2) 本网站对网络服务程度不承担任何责任，即用户对网络服务的使用承担风险。本网站不保证服务一定会满足用户的使用要求，也不保证服务不会受中断，对服务的及时性、安全性、准确性也不作担保。
                <br>
                (3) 为使用本网站服务，用户必须：
                <br>
                a.自行配备进入国际互联网所必需的设备，包括计算机、数据机或其他存取装置；
                <br>
                b.自行支付与此服务有关的费用。
                <br>
                (4) 用户接受本网站服务须同意：
                <br>
                a.提供完整、真实、准确、最新的个人资料；
                <br>
                b.不断更新注册资料，以符合上述（4）a的要求。
                <br>
                若用户提供任何错误、不实、过时或不完整的资料，并为本网站所确知；或者本网站有合理理由怀疑前述资料为错误、不实、过时或不完整，本网站有权暂停或终止用户的帐号，并拒绝现在或将来使用本网站服务的全部或一部分。
                </p>
                <div class="contract-content">3、用户应遵守以下法律及法规：</div>
                <p>
                    用户同意遵守《中华人民共和国保守国家秘密法》、《中华人民共和国计算机信息系统安全保护条例》、《计算机软件保护条例》等有关计算机及互联网规定的法律和法规、实施办法。在任何情况下，本网站合理地认为或可以明确证明用户的行为违反上述法律、法规，本网站可以在任何时候，不经事先通知终止向该用户提供服务。
                    用户应了解国际互联网的无国界性，应特别注意遵守当地所有有关的法律和法规。
                </p>
                <div class="contract-content">
                    4、用户隐私权制度
                </div>
                <p>
                    当用户注册本网站的服务时，用户须提供个人信息。在此过程中，本网站不会在未经合法用户授权时，公开、编辑或透露其个人信息及保存在本网站中的非公开内容，除非有下列情况：<br>
                </p>
                <p>
                （1）有关法律规定或本网站合法服务程序规定；
                <br>
                （2）在紧急情况下，为维护用户及公众的权益；
                <br>
                （3）为维护本网站的合法权益；
                <br>
                （4）其他需要公开、编辑或透露个人信息的情况。<br>
                在以下（包括但不限于）几种情况下，本网站有权使用用户的个人信息：<br>
                （1）在进行促销或抽奖时，本网站可能会与赞助商共享用户的个人信息，在这些情况下本网站会在发送用户信息之前进行提示，并且用户可以通过不参与来终止传送过程。
                <br>
                （2）本网站可以将用户信息与第三方数据匹配。
                <br>
                （3）本网站会通过透露合计用户统计数据，向未来的合作伙伴、广告商及其他第三方以及为了其他合法目的而描述本网站的服务。<br>
                （4）用户购买在本网站列出的商品时，本网站获得并使用用户提供的信息（例如用户的信用卡号和联系信息），并此类信息严格保密。<br>
                （5）本网站会向用户发送关于本网站不同产品和服务的信息或者本网站认为用户会感兴趣的其他产品和服务。如果用户不希望收到这样的信息或邮件，只需在提供个人信息时或其他任何时候告知即可。另外，本网站会竭尽全力保护用户的信息，但本网站不能确信或保证任何个人信息的绝对安全性，用户须自己承担风险。比如用户联机公布可被公众访问的个人信息时，用户有可能会收到未经用户同意的消息；本网站的合作伙伴和可通过本网站访问的第三方因特网站点和服务或通过抽奖、促销等活动得知用户个人信息的其他第三方会进行独立的数据收集工作等活动，本网站对用户及其他任何第三方的上述行为，不负担任何责任。
                </p>
                <div class="contract-content">5、 用户帐号、密码和安全</div>
                <p>
                    用户一旦注册成功，便成为本网站的合法用户，将得到一个密码和帐号。用户有义务保证密码和帐号的安全。用户对利用该密码和帐号所进行的一切活动负全部责任；因此所衍生的任何损失或损害，本网站无法也不负担任何责任。
                    用户的密码和帐号遭到未授权的使用或发生其他任何安全问题，用户可以立即通知本网站，并且用户在每次连线结束，应结束帐号使用，否则用户可能得不到本网站的安全保护。
                </p>
                <div class="contract-content">6、对用户信息的存储和限制</div>
                <p>
                    本网站不对用户所发布信息的删除或储存失败负责。本网站有权判断用户的行为是否符合本网站服务条款规定的权利，如果本网站认为用户违背了服务条款的规定，本网站有中断向其提供网络服务的权利。<br>
                </p><div class="contract-content">
                7、禁止用户从事以下行为：
                </div><p>
                （1）上载、张贴、发送或传送任何非法、有害、胁迫、骚扰、侵害、中伤、粗俗、猥亵、诽谤、淫秽、侵害他人因隐私、种族歧视或其他另人不快的包括但不限于资讯、资料、名称、文字、软件、音乐、照片、图形、信息或其他资料（以下简称内容）。
                <br>
                （2）以任何方式危害未成年人。
                <br>
                （3）冒充任何人或机构，或以虚伪不实的方式谎称或使人误认为与任何人或任何机构有关。
                <br>
                （4）伪造标题或以其他方式操控识别资料，使人误认为该内容为本网站所传送。
                <br>
                （5）将无权传送的内容（例如内部资料、机密资料）进行上载、张贴、发送电子邮件或以其他方式传送。
                <br>
                （6）将侵犯任何人的专利、商标、著作权、商业秘密或其他专属权利之内容加以上载、张贴、发送电子邮件或以其他方式传送。
                <br>
                （7）将广告函件、促销资料、"垃圾邮件"等，加以上载、张贴、发送电子邮件或以其他方式传送。供前述目的使用的专用区域除外。
                <br>
                （8）将有关干扰、破坏或限制任何计算机软件、硬件或通讯设备功能的软件病毒或其他计算机代码、档案和程序之资料，加以上载、张贴、发送电子邮件或以其他方式传送。
                <br>
                （9）干扰或破坏本网站服务或与本网站服务相连的服务器和网络，或不遵守本网站服务网络使用之规定。
                <br>
                （10）故意或非故意违反任何相关的中国法律、法规、规章、条例等其他具有法律效力的规范。
                <br>
                （11）跟踪或以其他方式骚扰他人。
            </p><p>
                用户对经由本网站服务上载、张贴、发送电子邮件或传送的内容负全部责任。 对于经由本网站服务而传送的内容，本网站不保证前述内容的正确性、完整性或品质。用户在接受本网站服务时，有可能会接触到令人不快、不适当或令人厌恶的内容。在任何情况下，本网站均不对任何内容负责，包括但不限于任何内容发生任何错误或纰漏以及衍生的任何损失或损害。本网站有权（但无义务）自行拒绝或删除经由本网站服务提供的任何内容。用户使用上述内容，应自行承担风险。本网站有权利在下述情况下，对内容进行保存或披露：
                <br>
            </p><p>
                （1）法律程序所规定；
                <br>
                （2）本网站服务条款规定；
                <br>
                （3）被侵害的第三人提出权利主张；<br>
                （4）为保护本网站、其使用者及社会公众的权利、财产或安全。
                <br>
                （5）其他本网站认为有必要的情况。
            </p><div class="contract-content">
                8、关于用户在本网站的公开使用区域张贴内容：
            </div><p>
                （1）"公开使用区域"指一般公众可以使用的区域；<br>
                （2）用户在本网站服务公开的使用区域张贴的内容，则视为用户授权本网站免费使用权及非独家使用权，本网站有权为展示、散布及推广前述张贴的内容之服务目的，对上述内容进行复制、修改、出版。
                <br>
                （3）因用户进行上述张贴，而导致任何第三方提出索赔要求或衍生的任何损害或损失，用户承担全部责任。
            </p><div class="contract-content">
                9、用户不得对本网站服务进行复制、出售或用作其他商业用途。</div><br><div class="contract-content">
                10、本网站有权规定并修改使用本网站服务的一般措施，包括但不限于决定保留电子邮件讯息或其他上载内容的时间、限制本网站服务一个帐号可接收电子邮件的数量等措施。如本网站未能储存或删除本网站服务的内容或其他讯息，本网站不负担任何责任。对于用户长时间未使用的帐号，本网站有权予以关闭。</div><br><div class="contract-content">
                11、与广告商的商业交易</div><p>
                用户经由本网站服务与广告商进行通讯联系或商业往来或参与促销活动，完全属于用户与广告商之间的行为，与本网站没有任何关系，对于前述交易或前述广告商因本网站服务所生之任何损害或损失，本网站不承担任何责任。本网站没有义务对广告及广告商进行甄别或审查。
            </p><div class="contract-content">
                12、接受有关商业资料的相关服务
            </div><p>
                用户从本网站服务接受任何商业资料，本网站服务对正确性、适用性，均不负任何责任。基于上述资料，用户所进行的交易决定，由用户承担全部后果和责任。
            </p><div class="contract-content">
                13、关于链接
            </div><p>
                本网站服务可能会提供与其他国际互联网网站或资源进行链接。对于前述网站或资源是否可以利用，本网站不予担保。因使用或依赖上述网站或资源所生的损失或损害，本网站也不负担任何责任。
            </p><div class="contract-content">
                14、本网站的知识产权及其他权利</div><p>
                （1）本网站对本网站服务及本网站服务所使用的软件包含受知识产权或其他法律保护的资料享有相应的权利；<br>
                （2）经由本网站服务传送的资讯及内容，受到著作权法、商标法、专利法或其他法律的保护；未经本网站明示授权许可，用户不得进行修改、出租、散布或衍生其他作品。
                <br>
                （3）用户对本网站服务所使用的软件有非专属性使用权，但自己不得或许可任何第三方复制、修改、出售或衍生产品。
                <br>
                （4）本网站设计图样以及其他本网站图样、产品及服务名称，均为本网站及产品所享有的商标、标识。任何人不得使用、复制或用作其他用途。
            </p><div class="contract-content">
                15、免责声明
            </div><p>
                （1）本网站对于任何包含、经由或连接、下载或从任何与有关本网站服务所获得的任何内容、信息或广告，不声明或保证其正确性或可靠性；并且对于用户经本网站服务上的广告、展示而购买、取得的任何产品、信息或资料，本网站不负保证责任。用户自行负担使用本网站服务的风险。
                <br>
                （2）本网站有权但无义务，改善或更正本网站服务任何部分之任何疏漏、错误。
                <br>
                （3）本网站不保证（包括但不限于）：
                <br>
                a.本网站服务适合用户的使用要求；
                <br>
                b.本网站服务不受干扰，及时、安全、可靠或不出现错误；
                <br>
                c.用户经由本网站服务取得的任何产品、服务或其他材料符合用户的期望；
                <br>
                （4）用户使用经由本网站服务下载的或取得的任何资料，其风险自行负担；因该使用而导致用户电脑系统损坏或资料流失，用户应负完全责任；
                <br>
                （5）基于以下原因而造成的利润、商业信誉、资料损失或其他有形或无形损失，本网站不承担任何直接、间接、附带、衍生或惩罚性的赔偿：
                <br>
                a.本网站服务使用或无法使用；
                <br>
                b.经由本网站服务购买或取得的任何产品、资料或服务；<br>
                c.用户资料遭到未授权的使用或修改；
                <br>
                d.其他与本网站服务相关的事宜。
            </p><div class="contract-content">
                16、由于用户经由本网站服务张贴或传送内容、与本网站服务连线、违反本网站服务条款或侵害其他人的任何权利导致任何第三人提出权利主张，用户同意赔偿本网站及相关第三方，并使其免受损害。</div><br><div class="contract-content">
                17、服务的修改和终止</div><p>
                本网站有权在任何时候，暂时或永久地修改或终止本网站服务（或任何一部分），无论是否通知。本网站对本网站服务的修改或终止对用户和任何第三人不承担任何责任。本网站有权基于任何理由，终止用户的帐号、密码或使用本网站服务，或删除、转移用户存储、发布在本网站服务的内容，本网站采取上述行为均不需通知，并且对用户和任何第三人不承担任何责任。
            </p><div class="contract-content">
                18、通知</div><p>
                本网站向用户发出的通知，采用电子邮件、网站信使、页面公告或常规信件的形式。服务条款的修改或其他事项变更时，本网站将会以上述形式进行通知。
            </p><div class="contract-content">
                19、本网站服务条款规范用户使用本网站服务，将取代用户先前与本网站签署的任何其他关于本网站服务的协议。</div><br><div class="contract-content">
                20、法律的适用和管辖</div><p>
                本网站服务条款的生效、履行、解释及争议的解决均适用中华人民共和国法律，由北京仲裁委员会管辖。本网站服务条款因与中华人民共和国现行法律相抵触而导致部分无效，不影响其他部分的效力。
            </p>
        </el-dialog>
    </div>
</div>
</template>
<script>
export default {
    name: 'ServicePolicy',
    props:['name','showDialog'],
    components:{},
    data(){
        return {

        }
    },
    computed:{},
    watch:{},
    created(){},
    mounted(){},
    methods:{
        handleClose(){
            this.$emit('closeServicePolicyDialog')
        }
    }
}
</script>

<style lang='scss' scoped>
.contract-content {
    text-align: left;
}
</style>
