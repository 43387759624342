<template>
<div class="forget-pwd" :style="{'--inputFocusColor':inputFocusBottomColor}">
    <div class="title">
        <div class="title-left">
            <!-- 忘记密码 -->
            <div :class="{'nav-pane':true,  'nav-selected':item.id == currentTag}" @click="clickNav(item)" v-for="item in navList" :key="item.modeCode">
                <div class="nav-pane-title">{{item.name}}</div>
                <svg-icon iconName="forget-tab-icon" v-if="item.id == currentTag" :config="config" :previewConfig="previewConfig" :Mode="Mode"></svg-icon>
            </div>
        </div>
        <div class="title-right" @click="switchToLogin">
            <div :style="{'color':`${specialTextColor} !important`}">登录</div>
            <svg-icon iconName="back-icon" :previewConfig="previewConfig" :config="config" :Mode="Mode"></svg-icon>
        </div>
    </div>
    <!------------------------------------------------------------------------------------------->
    <div v-show="currentTab == '邮箱'">
    <!-- 邮箱输入区域 -->
        <div class="input-area" v-show="showSendEmail">
            <div @click="inputSelected = '邮箱'" :class="{'input-focus':inputSelected=='邮箱','email-container':true, 'email-container-error':isInvalidEmail == true}">
                <input
                v-model="email" 
                type="text"
                @blur="inputBlur" 
                :class="{
                    'clear-input':true,
                    'email':true,
                    'email-error':isInvalidEmail == true,
                }"
                spellcheck="false" 
                placeholder="邮箱">
                <img v-cdnimg v-show="isInvalidEmail == true" @click="clickInvalidEmailIcon" class="email-error-icon" src="/Account/images/signin/errorIcon.png" alt="">
            </div>
            <div @click="inputSelected = '验证码'" :class="{'input-focus':inputSelected=='验证码','verify-code-container':true}">
                <input 
                v-model="verifyCode" 
                type="text" 
                @blur="inputBlur"
                class="clear-input general-verify-code" 
                spellcheck="false" 
                placeholder="图形验证码">
                <div class="icon-container" @click="updateVerifyCode">
                    <img :src="verifyCodeURL" class="code-img" alt="">
                    <img v-cdnimg src="/Account/images/signin/updateIcon.png" :class="{'update-code-icon':true, 'update-animation':clickUpdateIcon}" alt="">
                </div>
            </div>
        </div>
        <div class="input-area" v-show="showValidEmailCode">
            <div @click="inputSelected = '邮箱'" :class="{'input-focus':inputSelected=='邮箱','email-container':true, 'email-container-error':isInvalidEmail == true}">
                <input 
                v-model="emailValidCode" 
                type="text"
                @blur="inputBlur" 
                :class="{
                    'clear-input':true,
                    'email':true,
                    'email-error':isInvalidEmail == true,
                }" 
                spellcheck="false" 
                placeholder="邮箱验证码">
                <img v-cdnimg v-show="isInvalidEmail == true" @click="clickInvalidEmailIcon" class="email-error-icon" src="/Account/images/signin/errorIcon.png" alt="">
            </div>
            <div class="valid-code-tip">
                如果没有收到邮件，可以
                <span class="resend-code" @click="resendCode">重新发送验证码</span>
            </div>
        </div>

        <div class="input-area" v-show="showSetNewPwd">
            <div class="email-user-tip">您为账号<span class="account-name">{{accountName}}</span>重置密码</div>
            <div @click="inputSelected = '邮箱'" :class="{'input-focus':inputSelected=='邮箱','email-container':true, 'email-container-error':isInvalidEmail == true}">
                <input 
                v-model="newPwd" 
                type="password"
                @blur="inputBlur" 
                :class="{
                    'clear-input':true,
                    'email':true,
                    'email-error':isInvalidEmail == true,
                }"
                spellcheck="false" 
                placeholder="新密码">
                <img v-cdnimg v-show="isInvalidEmail == true" @click="clickInvalidEmailIcon" class="email-error-icon" src="/Account/images/signin/errorIcon.png" alt="">
            </div>
            <div @click="inputSelected = '邮箱'" :class="{'input-focus':inputSelected=='邮箱','email-container':true, 'email-container-error':isInvalidEmail == true}">
                <input 
                v-model="newPwdValid" 
                type="password"
                @blur="inputBlur" 
                :class="{
                    'clear-input':true,
                    'email':true,
                    'email-error':isInvalidEmail == true,
                }" 
                spellcheck="false" 
                placeholder="确认密码">
                <img v-cdnimg v-show="isInvalidEmail == true" @click="clickInvalidEmailIcon" class="email-error-icon" src="/Account/images/signin/errorIcon.png" alt="">
            </div>
        </div>
        <!-- 发送邮箱验证码按钮 -->
        <div class="submit-btn" @click="clickSubmitBtn" v-show="showSendEmail" :style="{'background-color':submitBtnBackgroundColor}">
            <div class="login-text">提&nbsp;交</div>
        </div>
        <!-- 校验验证码按钮 -->
        <div class="submit-btn" @click="validEmailCode" v-show="showValidEmailCode" :style="{'background-color':submitBtnBackgroundColor}">
            <div class="login-text">提&nbsp;交</div>
        </div>
        <!-- 确认按钮 -->
        <div class="submit-btn" @click="confirmChangePwd" v-show="showSetNewPwd" :style="{'background-color':submitBtnBackgroundColor}">
            <div class="login-text">确&nbsp;认</div>
        </div>
        <div class="error-tip" v-show="isInvalidEmail == true">
            当前邮箱不是注册邮箱
        </div>
    </div>

    <!------------------------------------------------------------------------------------------->
    <div v-show="currentTab == '手机'" class="message-login">
        <div v-show="showSendMessage">
            <input v-model="phoneNum" type="text" spellcheck="false" placeholder="手机号"
            @blur="inputBlur"
            @click="inputSelected = '手机号'"
            :class="{'clear-input':true, 'message-input':true, 'input-focus':inputSelected=='手机号'}" 
            >
            <div class="number-code-container"  @click="inputSelected = '图形验证码'" :class="{'input-focus':inputSelected=='图形验证码'}">
                <input 
                v-model="numCode" 
                type="text"
                @blur="inputBlur" 
                class="clear-input general-verify-code" 
                spellcheck="false" 
                placeholder="图形验证码">
                <div class="icon-container" @click="updateValidateCode">
                    <img :src="numCodeURL" class="code-img" alt="">
                    <img v-cdnimg src="/Account/images/signin/updateIcon.png" :class="{'update-code-icon':true, 'number-animation':phoneClickUpdateIcon}" alt="">
                </div>
            </div>
            <div :class="{'verify-code-container':true, 'input-focus':inputSelected=='短信验证码'}">
                <input v-model="messageCode" type="text" spellcheck="false" placeholder="短信验证码"
                @blur="inputBlur"
                class="clear-input general-verify-code"
                @click="inputSelected = '短信验证码'"
                >
                <div class="get-verify-code" v-show="showText" @click="getVerifyCode" :style="{'color':`${specialTextColor} !important`}">
                    获取验证码
                </div>
                <div v-show="!showText" :class="{'update-code':true, 'update-animation':clickUpdate}" alt="">
                    <span class="countdown">{{countdown}}</span>
                    <span> 秒后可重新发送</span>
                </div>
            </div>
            <!-- 发送到手机验证码按钮 -->
            <div class="submit-btn" @click="clickPhoneSubmitBtn" :style="{'background-color':submitBtnBackgroundColor}">
                <div class="login-text">提&nbsp;交</div>
            </div>
        </div>
        <div v-show="resetPhonePwd">
            <div class="phone-pwd-set-tip">绑定了该手机号的所有账号将被统一重置密码</div>
            <input 
            v-model="phoneNewPwd" 
            type="password"
            @blur="inputBlur" 
            :class="{
                'clear-input':true,
                'phone-pwd':true,
            }"
            spellcheck="false" 
            placeholder="新密码">
            <input 
            v-model="phoneNewPwdCopy" 
            type="password"
            @blur="inputBlur" 
            :class="{
                'clear-input':true,
                'phone-pwd':true,
            }"
            spellcheck="false" 
            placeholder="确认密码">
            <!-- 确认按钮 -->
            <div class="phone-submit-btn" @click="confirmPhoneChangePwd" :style="{'background-color':submitBtnBackgroundColor}">
                <div class="login-text">确&nbsp;认</div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import SvgIcon from "./SvgIcon.vue"
import {GET_BACK_PWD_BY_EMAIL, VALIDATE_NUM_CODE,CHECK_EMAIL_CODE,SET_NEW_PWD, SEND_MESSAGE_OF_FORGET_PWD,
MODIFY_PWD_BY_PHONE, GET_ACCOUNT_INFO} from '@/http/api.js'
import {getBaseUrl} from '@/http/http.js'
  export default {
    components: { SvgIcon },
    name: "ForgetPwd",
    props: ['config','previewConfig','Mode','bindEnterFlag'],
    data() {
      return {
        email:'',         // 邮箱
        verifyCode:'',    // 验证码
        verifyCodeURL:'',       // 验证码的图片地址
        clickUpdateIcon:false,  // 是否点击刷新验证码图标了
        isInvalidEmail:false,    // 邮箱是否是注册邮箱 ，true表示不是注册邮箱，false是注册邮箱
        inputSelected:'',       // 被选中的输入框名字，来添加不同的底部颜色
        emailValidCode:'',       // 邮箱验证码
        newPwd:'',                // 新密码
        newPwdValid:'',            // 二次输入新的密码
        showSendEmail:true,      // 显示向邮箱发验证码的内容
        showValidEmailCode:false,  // 显示输入邮箱验证码的页面
        showSetNewPwd:false,       // 显示设置新密码的页面
        sessionData:'',           // session信息
        currentTag:2,            // 所点击的导航的id
        navList:[                 // 导航条
            {name:'手机找回密码',id:2},
            {name:'邮箱找回密码',id:1},
        ],               
        currentTab:'手机',

        phoneNum:'',              // 手机号
        messageCode:'',           // 短信验证码
        showText:true,           // 是否显示倒计时的文本
        countdown:60,             // 倒计时
        numCode:'',            // 数字验证码
        numCodeURL:'',        // 图形验证码地址
        clickUpdate:false,    // 是否点击了更新验证码 
        phoneClickUpdateIcon:false,   // 手机号找回的
        showSendMessage:true,         // 是否显示向手机发短信的按钮
        resetPhonePwd:false,          // 手机号找回重新设置密码
        phoneNewPwd:'',             // 新密码
        phoneNewPwdCopy:'',        //  重复输入的新密码
        accountName:'',            // 用户名
        isShowPhoneSet:false,       // 是否显示手机找回设置新密码的页面
      }
    },
    watch:{
        bindEnterFlag:{
            handler:function(newVal, oldVal){
                let that = this
                if(newVal == '忘记密码'){
                    document.onkeydown = function(event){
                        if(event.key == 'Enter'){
                            if(that.currentTab == '邮箱'){
                                that.clickSubmitBtn() 
                            }else if(that.currentTab == '手机'){
                                that.clickPhoneSubmitBtn()
                            }
                        }
                    }
                    this.updateValidateCode();
                    this.showText = true;
                    this.currentTab = '手机';
                    this.currentTag = 2;
                    this.showSendMessage = true; // 显示手机第一步
                    this.resetPhonePwd = false; // 隐藏手机第二步
                    this.phoneNum = '';
                    this.messageCode = '';
                    this.phoneNewPwd = '';
                    this.phoneNewPwdCopy = '';
                }
            },
            immediate: true
        }
    },
    computed:{
        specialTextColor(){     // 登录 > 的颜色
            if(this.Mode == 'preview'){    // 预览模式
                return this.previewConfig.colorConfig.otherText
            }else{                       
                return this.config.colorConfig.otherText
            }
        },
        submitBtnBackgroundColor(){   // 提交按钮的背景色
            if(this.Mode == 'preview'){    // 预览模式
                return this.previewConfig.colorConfig.loginButton
            }else{                       
                return this.config.colorConfig.loginButton
            }
        },
        inputFocusBottomColor() {   // 点击输入框时输入框底部的颜色
            if(this.Mode == 'preview'){    // 预览模式
                return this.previewConfig.colorConfig.inputBoxSelect
            }else{                       
                return this.config.colorConfig.inputBoxSelect
            }
        }
    },
    methods: {
        sendMessage(){   // 发送短信
            let param = {
                Number:this.phoneNum,
                ImageVerificoationCode:this.numCode,
                stamp:this.getSessionVal('stamp'),
            }
            if(location.href.indexOf('lenovo') != -1){   // 联想平台下的
                param.signname = '联想'
            }
            SEND_MESSAGE_OF_FORGET_PWD(param).then((res)=>{
                let returnCode = res.data.ReturnCode
                if(returnCode == '1'){
                    this.showText = false   // 显示倒计时
                    this.countdown = 59     // 每次点击打开时重置为59
                    let timer = setInterval(()=>{
                        this.countdown--
                        if(this.countdown <= 0){
                            clearInterval(timer)
                            this.showText = true
                        }
                    },1000)
                    this.$message.success('发送成功!');
                }else if(returnCode == '-109001'){
                    this.$message.error('图形验证码无效');
                    this.updateValidateCode()  // 更新验证码 
                }else if(returnCode == -1){
                    this.$message.error(res.data.ReturnText);
                    this.updateValidateCode()  // 更新验证码
                }else{
                    this.$message.error('发送短信验证码失败');
                    this.updateValidateCode()  // 更新验证码 
                }
            })
        },
        async updateVerifyCode() {  // 更新验证码
            this.verifyCode = '';
            let stamp = this.getSessionVal('stamp');
            if(this.verifyCodeURL != '') {
                if(this.clickUpdateIcon == false) {
                    this.clickUpdateIcon = true;
                    setTimeout(async () => {
                        this.verifyCodeURL = await this.onGetCaptcha(stamp);
                        this.clickUpdateIcon = false;
                    }, 700)
                }
            } else {
                this.verifyCodeURL = await this.onGetCaptcha(stamp);
            }
        },
        switchToLogin(){   // 切换到登录页面
            this.$emit("switchToLogin")
        },
        inputBlur() {
            this.inputSelected = ''
        },
        CheckEmail(email) {   // 验证邮箱格式
            var myreg = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
            return myreg.test(email)
        },
        clickSubmitBtn(){  // 点击提交：发邮件找回密码
            this.isInvalidEmail = false
            if (!this.CheckEmail(this.email)) {
                this.$message.error('邮箱格式不正确，请输入有效邮箱!');
            }else{
                if(this.email == ''){
                    this.$message.error('请输入邮箱!');
                    return false
                }
                if(this.verifyCode == ''){
                    this.$message.error('请填写验证码!');
                    return false
                }
                let code = this.verifyCode.toLowerCase()  // 转换为小写
                if(this.CheckEmail(this.email)){
                    let stamp = this.getSessionVal('stamp')
                    let param = {
                        session:"",
                        email:this.email,
                        code:code,
                        stamp:stamp,
                        page:"0"
                    }
                    GET_BACK_PWD_BY_EMAIL(this.$qs.stringify(param)).then(res => {
                        let result = res.data;
                        if(this.isValid(result)) {
                            if(result.retmsg == "code is not correct") {
                                this.$message.error('请输入正确的验证码!');
                                this.updateVerifyCode();
                                return;
                            }
                            if(result.retmsg == "invalid email") {
                                this.$message.error('当前邮箱不是注册邮箱!');
                                this.isInvalidEmail = true;    // 当前邮箱不是注册邮箱
                                this.updateVerifyCode();
                                return;
                            }
                            if(result.retmsg == 'captcha code expired') {
                                this.$message.error('验证码已过期!');
                                this.updateVerifyCode();
                                return;
                            }
                            if(result.retmsg == "success") {
                                this.$message.success('邮件已发送，请注意查收!');
                                this.showSendEmail = false;
                                this.showValidEmailCode = true;
                                this.showSetNewPwd = false;
                            }
                        }
                    })
                }
            }
        },
        validEmailCode(){   // 验证一下邮箱验证码
            if(this.emailValidCode == ''){
                this.$message.error('请输入邮箱验证码!');
            }else{
                let param = {
                    session:'',
                    code:this.emailValidCode,
                    stamp:this.getSessionVal('stamp'),
                    page:0
                }
                CHECK_EMAIL_CODE(this.$qs.stringify(param)).then((res)=>{
                    if(res.data.retcode == 0){
                        this.$message({
                            message: '验证成功',
                            type: 'success'
                        })
                        this.sessionData = res.data.result[0]
                        this.showSendEmail = false
                        this.showValidEmailCode = false
                        this.showSetNewPwd = true    // 显示设置新密码
                        this.getAccountName()
                    }else{
                        this.$message.error('邮箱验证码无效!');
                    }
                })
            }
        },
        getAccountName(){    // 获取当前验证码对应的用户名
            let param = {
                session: this.sessionData,
                page:0
            }
            GET_ACCOUNT_INFO(this.$qs.stringify(param)).then((response)=>{    // 获取个人信息,实际上这个接口数据和LoginSession2Json里面的数据都不全，所以要凑到一块
                let userInfo = response.data.result[0]
                this.accountName = userInfo.realname || userInfo.account
            })
        },
        confirmChangePwd(){  // 确认修改密码
            let newpwd = this.newPwd
            let confirmp = this.newPwdValid
            if (newpwd == "") {
                this.$message.error('请输入新密码');
                return;
            }
            if (confirmp == "") {
                this.$message.error('请输入确认密码');
                return;
            }
            if (confirmp != newpwd) {
                this.$message.error('两次输入不相同');
                return;
            }
            if(!this.checkPassword(newpwd) || newpwd.length < 8){
                this.$message.error('大写字母、小写字母、数字、特殊符号至少包含3种，长度≥8位');
                return
            }
            let param = {
                session: this.sessionData,
                page:'0',
                newpassword:this.encrypt(newpwd)
            }            
            SET_NEW_PWD(this.$qs.stringify(param)).then((res)=>{
                if(res.data.ReturnCode == 1){
                    this.$message({
                        message: '修改成功，请重新登录',
                        type: 'success'
                    })
                    if(this.IsClient) {   // 客户端
                        CallCPP('<command name="close"></command>')
                    } else {
                        this.switchToLogin()   // 切换到登录页面
                    }
                }else{
                    this.$message.error('修改失败，请稍后再试');
                }
            })
        },
        confirmPhoneChangePwd(){  // 确认修改密码
            let newpwd = this.phoneNewPwd
            let confirmp = this.phoneNewPwdCopy
            if (newpwd == "") {
                this.$message.error('请输入新密码');
                return;
            }
            if (confirmp == "") {
                this.$message.error('请输入确认密码');
                return;
            }
            if (confirmp != newpwd) {
                this.$message.error('两次输入不相同');
                return;
            }
            if(!this.checkPassword(newpwd) || newpwd.length < 8){
                this.$message.error('大写字母、小写字母、数字、特殊符号至少包含3种，长度≥8位');
                return
            }
            let param = {
                Number:this.phoneNum,
                VerificoationCode:this.messageCode,
                Password:this.phoneNewPwdCopy
            }
            MODIFY_PWD_BY_PHONE(param).then((res)=>{
                if(res.data.ReturnCode == 1){
                    this.$message({
                        message: '修改成功，请重新登录',
                        type: 'success'
                    })
                    if(this.IsClient) {   // 客户端
                        CallCPP('<command name="close"></command>')
                    } else {
                        this.showSendMessage = true
                        this.resetPhonePwd = false
                        this.switchToLogin()   // 切换到登录页面
                    }
                }else{
                    this.$message.error('修改失败，请稍后再试');
                }
            })
        },
        clickInvalidEmailIcon(){   // 无效邮箱右侧会出现一个红色x按钮，点击之后调这个方法清空邮箱，重置样式
            this.email = ''   // 清空邮箱
            this.isInvalidEmail = false   // 不显示警告样式
        },
        resendCode(){  // 重新发送验证码
            this.showSendEmail = true
            this.showValidEmailCode = false
            this.showSetNewPwd = false
        },
        clickNav(item){
            this.currentTag = item.id
            if(item.id == 1){
                this.currentTab = '邮箱'
                this.phoneNum = ''; // 清空手机号
                this.messageCode = ''; // 清空短信验证码
                this.phoneNewPwd = ''; // 清空密码
                this.phoneNewPwdCopy = ''; // 清空确认密码
                this.showSendEmail = true; // 隐藏邮箱一二三步
                this.showValidEmailCode = false; 
                this.showSetNewPwd = false;
                this.updateVerifyCode(); // 更新邮箱验证码
            }else{
                this.currentTab = '手机'
                this.email = ''; // 清空邮箱
                this.emailValidCode = ''; // 清空邮箱验证码
                this.newPwd = ''; // 清空密码
                this.newPwdValid = ''; // 清空确认密码
                this.showText = true;
                this.showSendMessage = true; // 显示手机第一步
                this.resetPhonePwd = false; // 隐藏手机第二步
                this.updateValidateCode(); // 更新手机验证码
            }
        },
        inputBlur(){  // 输入框失去焦点
            this.inputSelected = ''
        },
        getVerifyCode(){   // 点击获取短信验证码
            if(this.phoneNum == ''){
                this.$message.error('请输入手机号');
                this.updateValidateCode()  // 更新验证码 
            }else if(this.numCode == ''){
                this.$message.error('请输入图形验证码');
                this.updateValidateCode()  // 更新验证码 
            }else{
                var reg = /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/   // 手机号正则
                if(reg.test(this.phoneNum)){
                    this.sendMessage()    // 向手机发送验证码
                }else{
                    this.$message.error('该手机号无效，请重新输入');
                    this.phoneNum = ''    // 重置手机号
                    this.updateValidateCode()  // 更新验证码 
                }
            }
        },
        clickPhoneSubmitBtn(){   // 点击手机号找回的提交按钮
            if(this.phoneNum == ''){
                this.$message.error('请输入手机号码');
                return
            }else if(this.messageCode == ''){
                this.$message.error('请输入短信验证码');
            }else{
                this.showSendMessage = false
                this.resetPhonePwd = true
            }
        },
        async updateValidateCode() {  // 更新验证码 
            this.numCode = '';    //刷新验证码时把原来的验证码置空
            let stamp = this.getSessionVal('stamp');
            if(stamp == '') {
                stamp = uuidv4();
                this.updateSession('stamp', stamp);
            }
            if(this.numCodeURL != '') {
                if(this.phoneClickUpdateIcon == false) {     // 关闭旋转动画
                    this.phoneClickUpdateIcon = true;
                    setTimeout(async () => {
                        this.numCodeURL = await this.onGetCaptcha(stamp);
                        this.phoneClickUpdateIcon = false;
                    }, 700)
                }
            }else{
                this.numCodeURL = await this.onGetCaptcha(stamp);
            }
        }
    },
    created(){},
    mounted(){}
  }
</script>

<style lang="scss" scoped>
@import "@/styles/mixin.scss";
@include resetCheckbox();  // 清除原生input样式
.input-focus{
    border-bottom-color: var(--inputFocusColor) !important;
}
.forget-pwd{
    width: 100%;
    height: 100px;
    .title {
        padding-bottom: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .title-left {
            display: flex;
            .nav-pane{
                color: #6B7785;
                font-size: 14px;
                margin-right: 24px;
                cursor: pointer;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .nav-pane-title{
                    margin-bottom: 8px;
                }
            }
            .nav-selected{
                color: #1D2129;
                font-weight: 700;
                font-size: 16px;
            }
        }
        .title-right {
            font-size: 14px;
            font-weight: 500;
            color: #FF6A00;
            cursor: pointer;
            display: flex;
        }
    }
    .input-area{
        .email-user-tip {
            font-size: 14px;
            color: #1D2129;
            margin-bottom: 16px;
            .account-name{
                color: #F15F00;
            }
        }
        .email-container {
            border-bottom: 2px solid #F2F3F5;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 32px;
            .email{
                flex:1;
                box-sizing: border-box;
                height: 38px;
            }
            .email-error{
                background-color: #fff7f7;    
            }
            .email-error-icon {
                width: 20px;
                height: 20px;
                margin-right: 8px;
                cursor: pointer;
            }
        }
        .email-container-error{  // 邮箱不正确的时候的样式
            background-color: #fff7f7;
            border-bottom: 2px solid #FF3B30;
        }
        .verify-code-container {
            border-bottom: 2px solid #F2F3F5;
            margin-bottom: 24px; 
            display: flex;
            align-items: center;
            justify-content: space-between;
            .code-img{
                width: 100px;
                height: 40px;
            }
            .general-verify-code{  // 验证码
                box-sizing: border-box;
                height: 38px;
                border: 0px;
                flex: 1;
            }
            .icon-container{
                box-sizing: border-box;
                height: 40px;
                display: flex;
                align-items: center;
                cursor: pointer;
                .update-code-icon{
                    width: 18px;
                    height: 18px;
                    margin-right: 16px;
                    margin-top: 5px;
                }
                .update-animation{   // 点击刷新二维码的图标时旋转
                    animation: loading 0.7s ease-in-out infinite forwards;
                }
                @keyframes loading {  // 旋转动画
                    0% {
                        transform: rotate(0deg);
                    }
                    100% {
                        transform: rotate(-360deg);
                    }
                }
            }
        }

        .input{
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 16px 0 16px 0;
            border-bottom: 1.5px dashed #e1e2e4;
            cursor: pointer;
            .input-left{
                display: flex;
                .inputName {     // 角色名称
                    display: flex;
                    font-size: 13px;
                    color: #00AC98;
                    width: 72px;
                    height: 24px;
                    box-sizing: border-box;
                    background-color: #ebfbf9;
                    border-radius: 2px;
                    align-items: center;
                    justify-content: center;
                }           // 平台信息
                .platform {
                    margin-left: 16px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    font-size: 14px;
                    color: #1D2129;
                    .userName {
                        color: #4E5969;
                        margin-top: 4px;
                    }
                }
            }
            .input-right{
                display: flex;
                align-items: center;
                .right-icon {
                    width: 22px;
                    height: auto;
                }
            }
        }
        .valid-code-tip {    // 输入验证码的提示
            font-size: 13px;
            color: #6B7785;
        }
        .resend-code{   // 重新发送验证码
            color: #FF6A00;
            cursor: pointer;
        }
    }
    .message-login {
        display: flex;
        flex-direction: column;
        .phone-pwd-set-tip{
            color: #1D2129;
            font-size: 13px;
            margin-bottom: 16px;
        }
        .phone-pwd{
            flex:1;
            box-sizing: border-box;
            height: 38px;
            margin-bottom: 32px;
            border-bottom: 2px solid #F2F3F5;
            width: 100%;
        }
        .bind-account{
            display: flex;
            flex-direction: column;
            .message-login-pwd {
                margin-bottom: 0 !important;
            }
        }
        .general-message-login{
            display: flex;
            flex-direction: column;
        }
        .message-input{   // 短信登录的输入框
            box-sizing: border-box;
            height: 38px;
            border-bottom: 2px solid #F2F3F5;
            margin-bottom: 24px;
            width: 100%;
        }
        .phone-submit-btn{  // 提交
            width: 392px;
            height: 40px;
            background-color: #ff6a00;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            cursor: pointer;
                .login-text {
                    height: 22px;
                    color: #FFFFFF;
                    font-size: 16px;
                    font-weight: 500;
                }
            }
        .verify-code-container {
            border-bottom: 2px solid #F2F3F5;
            margin-bottom: 0; 
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 32px;
            .general-verify-code{  // 验证码
                box-sizing: border-box;
                height: 38px;
                border: 0px;
                flex: 1;
            }
            .get-verify-code {   // 获取验证码的文字
                width: 70px;
                height: 22px;
                color: #FF6A00;
                font-size: 14px;
                cursor: pointer;
                font-weight: 500;
                margin-top: 2px;
                margin-right: 8px;
            }
            .code-img{  // 验证码图标
                width: 48px;
                height: 17px;
                margin-right: 8px;
                margin-top: 2px;
                margin-left: auto;
            }
            .update-code{
                margin-top: 2px;
                width: 118px;
                height: 22px;
                font-size: 14px;
                color: #A9AEB8;
                margin-right: 8px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .countdown{
                    width: 17px;
                    text-align: right;
                }
            }
        }
        .number-code-container {
            border-bottom: 2px solid #F2F3F5;
            margin-bottom: 24px; 
            display: flex;
            align-items: center;
            justify-content: space-between;
            .code-img{
                width: 100px;
                height: 40px;
            }
            .general-verify-code{  // 验证码
                box-sizing: border-box;
                height: 38px;
                border: 0px;
                flex: 1;
            }
            .icon-container{
                box-sizing: border-box;
                height: 40px;
                display: flex;
                align-items: center;
                cursor: pointer;
                .update-code-icon{
                    width: 18px;
                    height: 18px;
                    margin-right: 16px;
                    margin-top: 5px;
                }
                .number-animation{   // 点击刷新二维码的图标时旋转
                    animation: loading 0.7s ease-in-out infinite forwards;
                }
                @keyframes loading {  // 旋转动画
                    0% {
                        transform: rotate(0deg);
                    }
                    100% {
                        transform: rotate(-360deg);
                    }
                }
            }
        }
        .tip {  // 提示语
            font-size: 13px;
            color: #FF3B30;
            font-weight: 400;
            margin-top: 16px;
        }
    }
    .submit-btn{  // 提交
    margin-top: 32px;
    width: 392px;
    height: 40px;
    background-color: #ff6a00;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
        .login-text {
            height: 22px;
            color: #FFFFFF;
            font-size: 16px;
            font-weight: 500;
        }
    }
    .error-tip {  // 错误提示
        font-size: 13px;
        color: #FF3B30;
        margin-top: 16px;
        font-weight: 500;
    }
}


</style>