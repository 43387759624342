<template>
<div class="forget-pwd" :style="{'--inputFocusColor':inputFocusBottomColor}">
    <div class="title">
        <!-- 导航区域 -->
        <div class="navigation">
            <div :class="{'nav-pane':true,  'nav-selected':item.id == currentTag.id}" @click="clickNav(item)" v-for="item in nav" :key="item.id">
                <div class="nav-pane-title">{{item.title}}</div>
                <svg-icon class="nav-pane-icon" iconName="common-tab-icon" v-if="item.id == currentTag.id" :config="config" :previewConfig="previewConfig" :Mode="Mode"></svg-icon>
            </div>
        </div>
        <div class="title-right" @click="switchToLogin">
            <div :style="{'color':`${specialTextColor} !important`}">登录</div>
            <svg-icon iconName="back-icon" :previewConfig="previewConfig" :config="config" :Mode="Mode"></svg-icon>
        </div>
    </div>
    <!-- 表单填写区域 -->
    <div class="form">
        <div class="first-line">
            <input  type="text" class="clear-input input-name" spellcheck="false" ref="username" placeholder="用户名"
            @click="inputSelected = '用户名'"
            v-model="userName"
            @blur="inputBlur"
            :class="{'input-focus':inputSelected=='用户名'}"
            >
            <input  type="text" class="clear-input input-name" spellcheck="false" ref="username" placeholder="姓名"
            @click="inputSelected = '姓名'"
            v-model="realName"
            @blur="inputBlur"
            :class="{'input-focus':inputSelected=='姓名'}"
            >
        </div>
        <input  type="password" class="clear-input general-input" spellcheck="false" placeholder="密码"
        @click="inputSelected = '密码'"
        v-model="password"
        @blur="inputBlur"
        :class="{'input-focus':inputSelected=='密码'}"    
        >
        <input  type="text" class="clear-input general-input" spellcheck="false" placeholder="邀请码"
        @click="inputSelected = '邀请码'"
        v-model="inviteCode"
        @blur="inputBlur"
        :class="{'input-focus':inputSelected=='邀请码'}"
        >
        <div class="verify-code-container" :class="{'input-focus':inputSelected=='验证码'}">
            <input  type="text" class="clear-input verify-code" spellcheck="false" placeholder="验证码"
            @click="inputSelected = '验证码'"
            v-model="validateCode"
            @blur="inputBlur"
            >
            <div class="icon-container" @click="updateVerifyCode">
                <img :src="verifyCodeURL" class="code-img" alt="">
                <img v-cdnimg src="/Account/images/signin/updateIcon.png" :class="{'update-code-icon':true, 'update-animation':clickUpdateIcon}" alt="">
            </div>
        </div>
    </div>
    <!-- 服务条款 -->
    <div class="clause">
        <div @click="clickCheckbox" class="clause-checkbox">
            <!-- 现在的方案是改用svg嵌在html中 -->
            <svg-icon iconName="checkbox-unchecked" v-show="isAgree == false" :previewConfig="previewConfig" :config="config" :Mode="Mode"></svg-icon>
            <svg-icon iconName="checkbox-checked" v-show="isAgree == true" :previewConfig="previewConfig" :config="config" :Mode="Mode"></svg-icon>
        </div>
        <div class="clause-text">
            <span @click="clickCheckbox" class="clause-agree-text">我已阅读并同意</span>
            <span class="clause-content" @click="showServicePolicy">服务条款</span>
        </div>
    </div>
    <!-- 提交按钮 -->
    <div class="signup-btn" @click="clickSignUpBtn" @keyup.enter="clickSignUpBtn" :style="{'background-color':submitBtnBackgroundColor}">
        <div class="signup-text">立即注册</div>
    </div>
    <!-- 服务条款 -->
    <service-policy class="policy-wrap" name="普通平台" :showDialog="showDialog" @closeServicePolicyDialog="closeServicePolicyDialog"></service-policy>
</div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import SvgIcon from './SvgIcon.vue'
import ServicePolicy from './ServicePolicy.vue'
import {
    CREATE_ACCOUNT,
    VALIDATE_CAPTCHA
} from '@/http/api.js'
  export default {
    name: "ForgetPwd",
    components: { SvgIcon, ServicePolicy },
    props: ['config','previewConfig','Mode','bindEnterFlag'],
    data() {
      return {
        email:'',         // 邮箱
        verifyCode:'',    // 验证码
        verifyCodeURL:'',      // 验证码的图片地址
        clickUpdateIcon:false,  // 是否点击刷新验证码图标了
        nav:[   // 导航区域
            {title:'学生', flag:true , id:0},
            {title:'教师', flag:false, id:1},
            {title:'家长', flag:false, id:2},
        ],
        currentTag:{title:'学生', flag:true , id:0},   // 保存当前所点击的导航下标
        inputSelected:'',   // 获取焦点的input框
        showDialog:false,    // 是否要显示注册服务条款弹窗
        role:'',            // 当前所选择的角色
        userName:'',     // 用户名
        realName:'',     // 真实姓名
        password:'',     // 密码
        inviteCode:'',   // 邀请码
        validateCode:'',  // 验证码
        isAgree:false,  // 是否同意条款
      }
    },
    watch:{
        bindEnterFlag:{
            handler:function(newVal, oldVal){
                let that = this
                if(newVal == '立即注册'){
                    document.onkeydown = function(event){
                        if(event.key == 'Enter'){
                            that.clickSignUpBtn() 
                        }
                    }
                    this.updateVerifyCode();
                }
            },
            immediate: true
        }
    },
    computed:{
        inputFocusBottomColor() {   // 点击输入框时输入框底部的颜色
            if(this.Mode == 'preview'){    // 预览模式
                return this.previewConfig.colorConfig.inputBoxSelect
            }else{                       
                return this.config.colorConfig.inputBoxSelect
            }
        },
        specialTextColor(){     // 登录 > 的颜色
            if(this.Mode == 'preview'){    // 预览模式
                return this.previewConfig.colorConfig.otherText
            }else{                       
                return this.config.colorConfig.otherText
            }
        },
        submitBtnBackgroundColor(){   // 立即注册按钮的背景色
            if(this.Mode == 'preview'){    // 预览模式
                return this.previewConfig.colorConfig.loginButton
            }else{                       
                return this.config.colorConfig.loginButton
            }
        }
    },
    methods: {
        async updateVerifyCode(){  // 更新验证码
            this.validateCode = '';
            let stamp = this.getSessionVal('stamp')
            if(this.verifyCodeURL != '') {
                if(this.clickUpdateIcon == false) {
                    this.clickUpdateIcon = true
                    setTimeout(async () => {
                        this.verifyCodeURL = await this.onGetCaptcha(stamp);
                        this.clickUpdateIcon = false
                    }, 700)
                }
            } else {
                this.verifyCodeURL = await this.onGetCaptcha(stamp);
            }
        },
        async checkValidateCode(){   // 调接口验证数字验证码
            let stamp = this.getSessionVal('stamp');
            if (stamp == "") stamp = uuidv4();
            let param = { code: this.validateCode, id: stamp };
            let isValidateSucc = false;
            await VALIDATE_CAPTCHA(param).then(res => {
                let data = res.data;
                if(data?.data) {
                    isValidateSucc = true;
                } else {
                    this.$message.error('验证码错误!');
                    this.updateVerifyCode();
                }
            })
            return isValidateSucc;
        },
        switchToLogin(){   // 切换到登录页面
            this.userName = '';
            this.realName = '';
            this.password = '';
            this.inviteCode = '';
            this.validateCode = '';
            this.isAgree = false;
            this.currentTag = this.nav[0];
            this.$emit("switchToLogin")
        },
        clickNav(item){    // 点击
            this.currentTag = item     // 确定当前点击的是哪个标签页
            this.role = item.title        // 确定角色
        },
        clickCheckbox(){  // 点击我已却独并同意服务条款
            this.isAgree = !this.isAgree
        },
        inputBlur() {   // 输入框失去焦点的时候不显示下划线颜色
            this.inputSelected = ''
        },
        showServicePolicy() {  // 显示服务条款
            this.showDialog = true
        },
        closeServicePolicyDialog() {  // 关闭服务条款
            this.showDialog = false
        },
        handleSignUp(){
            let param = {
                session: '',
                page: 0,
                account: this.userName,  // 用户名
                password: this.password,       
                realName: this.realName,  // 姓名
                role: this.currentTag.title,   // 角色
                inviteCode: this.inviteCode  // 邀请码
            }
            CREATE_ACCOUNT(this.$qs.stringify(param)).then((res)=>{
                if(res.data.ReturnCode == 1) {
                    this.$message.success('注册成功!');
                    this.$emit("switchToLogin");        
                } else {
                    let returnText = res.data.ReturnText;
                    let text = '';
                    switch(returnText) {
                        case 'ErrCode:0 ErrCode:102 Invalidate invite code':
                            text = '邀请码无效!';
                            break;
                        case 'ErrCode:0 ErrCode:107 Invite code expired':
                            text = '邀请码已过期!';
                            break;
                        case 'ErrCode:0 ErrCode:101 Account is already exists':
                            text = '账户已存在!';
                            break;
                    }
                    this.inviteCode = '';
                    this.$message.error(text);
                    this.updateVerifyCode(); 
                }
            })
        },
        clickSignUpBtn(){   // 点击立即注册按钮
            let validTag = this.validateRegisterForm()   // 如果通过校验则会返回接口参数，没有则为空
            if (validTag) {   // 说明通过了校验
                this.checkValidateCode().then(isValidateNumCodeSucc => {
                    if(isValidateNumCodeSucc){   // 如果图形验证码验证通过
                        this.handleSignUp()   // 调用接口注册
                    }
                });
            }
        },
        validateRegisterForm(){        // 校验注册内容
            let nameReg = /^[\u4E00-\u9FA5()（）A-Za-z0-9_-]+$/
            if(this.userName == ''){
                this.$message.error('请填写用户名');
                return
            }else{
                // if (this.userName.length < 6 || this.userName.length > 50 || !nameReg.test(this.userName)) {
                if (this.userName.length < 6 || this.userName.length > 50) {
                    // this.$message.error('用户名为6-50个字符，中英文、数字、下划线的组合');
                    this.$message.error('用户名为6-50个字符');
                    return
                }
            }
            if(this.realName == ''){
                this.$message.error('请填写姓名');
                return
            }else{
                if(!nameReg.test(this.realName)) {
                    this.$message.error('请检查真实姓名');
                    return
                }
            }
            if(this.password == ''){
                this.$message.error('请填写密码');
                return
            }else{
                if(!this.checkPassword(this.password) || this.password.length < 8){
                    this.$message.error('大写字母、小写字母、数字、特殊符号至少包含3种，长度≥8位');
                    return
                }
            }
            if(this.inviteCode == ''){
                this.$message.error('请填写邀请码');
                return
            }
            if(this.validateCode == ''){
                this.$message.error('请填写验证码');
                return
            }
            if(this.isAgree == false){
                this.$message.error('请同意服务条款');
                return
            }
            return true;
        }
    },
    created(){},
    mounted(){
        this.updateVerifyCode()    // 页面初始化的时候要加载验证码
        if (this.isMobile()) {
            this.currentTag = { title: '教师', flag: false, id: 1 };
            this.nav = [this.currentTag];
        }
    }
  }
</script>

<style lang="scss" scoped>
@import "@/styles/mixin.scss";
@include resetCheckbox();  // 清除原生input样式
.input-focus{    // input获取焦点时下划线的颜色
    border-bottom-color: var(--inputFocusColor) !important;
}
.forget-pwd{
    width: 100%;
    height: 100px;
    .title {
        font-size: 16px;
        font-weight: 500;
        color: #1D2129;
        padding-bottom: 24px;
        display: flex;
        align-items: center;
        margin-top: -2px;
        justify-content: space-between;
        .navigation {
            width: 392px;
            height: 38px;
            display: flex;
            .nav-pane{
                width: 32px;
                height: 38px;
                margin-right: 34px;
                font-size: 14px;
                color: #6B7785;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
                .nav-pane-title {
                    height: 24px;
                    display: flex;
                    align-items: flex-end;
                }
                .nav-pane-icon{
                    width: 34px;
                    height: 10px;
                }
            }
            .nav-selected{
                font-size: 16px;
                font-weight: 600;
                color: #1D2129;
            }
        }
        .title-right {
            font-size: 14px;
            width: 55px;
            font-weight: 500;
            color: #FF6A00;
            cursor: pointer;
            display: flex;
            margin-bottom: 7px;
        }
    }
    .form {   // 普通登录
        display: flex;
        flex-direction: column;
        .first-line{
            display: flex;
            justify-content: space-between;
            .input-name {  // 用户名和姓名
                box-sizing: border-box;
                height: 38px;
                width: 184px;
                border: 0px;
                border-bottom: 2px solid #F2F3F5;
                margin-bottom: 32px;
            }
        }
        .general-input {  // 密码
            box-sizing: border-box;
            height: 38px;
            border: 0px;
            border-bottom: 2px solid #F2F3F5;
            margin-bottom: 32px;
        }
        .verify-code-container {
            border-bottom: 2px solid #F2F3F5;
            margin-bottom: 24px; 
            display: flex;
            align-items: center;
            justify-content: space-between;
            .code-img{
                width: 100px;
                height: 40px;
            }
            .verify-code{  // 验证码
                box-sizing: border-box;
                height: 38px;
                border: 0px;
                flex: 1;    
            }
            .icon-container{
                box-sizing: border-box;
                height: 40px;
                display: flex;
                align-items: center;
                cursor: pointer;
                .update-code-icon{
                    width: 18px;
                    height: 18px;
                    margin-right: 16px;
                    margin-top: 5px;
                }
                .update-animation{   // 点击刷新二维码的图标时旋转
                    animation: loading 0.7s ease-in-out infinite forwards;
                }
                @keyframes loading {  // 旋转动画
                    0% {
                        transform: rotate(0deg);
                    }
                    100% {
                        transform: rotate(-360deg);
                    }
                }
            }
        }
    }
    .clause{
        display: flex;
        align-items: center;
        .clause-checkbox{
            cursor: pointer;
        }
        .clause-text{
            color: #4E5969;
            font-size: 13px;
            line-height: 21px;
            margin-left: 7px;
            .clause-agree-text{
                cursor: pointer;
            }
            .clause-content {
                // color: #296BFD;
                color: #0099ff;
                cursor: pointer;
            }
        }

        label {
            position: relative;
            cursor: pointer;

            input {
                cursor: pointer;
            }

            input:checked + .show-box {
                background: #ff6a00;
                border: 1px solid white;
                border-radius: 2px;
            }

            .show-box {
                position: absolute;
                top: 0;
                left: 0;
                width: 15px;
                height: 15px;
                border-radius: 2px;
                border: 1px solid #d8d8d8;
                background: white; // 这里取个巧，与下面颜色一样而已

                &:before { // 使用了 absolute 所以无所谓是 before 还是 after
                    content: ''; // 空白内容占位，当做盒模型处理，见下面
                    position: absolute;
                    top: 1px;
                    left: 5.5px;
                    width: 3px; // 勾的短边
                    height: 8px; // 勾的长边
                    border: solid white; // 勾的颜色
                    border-width: 0 2px 2px 0; // 勾的宽度
                    transform: rotate(45deg); // 定制宽高加上旋转可以伪装内部的白色勾
                }
            }
        }
    }
    .signup-btn{  // 提交
        margin-top: 32px;
        width: 392px;
        height: 40px;
        background-color: #ff6a00;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        cursor: pointer;
        .signup-text {
            // width: 64px;
            height: 22px;
            color: #FFFFFF;
            font-size: 16px;
            font-weight: 500;
        }
    }
}
</style>

<style lang="scss" scoped>
@media screen and (max-width: 768px) {
    .forget-pwd {
        height: initial;

        .signup-btn {
            width: 100%;
        }

        .input-name {
            width: 45% !important;
        }

        .title-right {
            flex-shrink: 0;
        }

        .verify-code {
            width: 180px !important;
        }
    }

    .policy-wrap ::v-deep .el-dialog {
        width: 95% !important;
    }
}  
</style>