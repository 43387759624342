<template>
<div class="choose-role">
    <div v-show="showStatus == '账号'">
        <div class="title">请选择登录账号</div>
        <!-- 选择登录账号区域 -->
        <div class="role-area">
            <div class="role" v-for="(item,index) in accountList" @click="clickAccount(item)" :key="index">
                <div class="role-left">
                    <div class="roleName">{{ item.RealName || item.realname || item.LoginName || item.loginname }}</div>
                    <div class="platform">
                        <div class="platform-name">{{ item.platformname }}</div>
                        <div class="userName">{{ roleList.showname }}</div>
                    </div>
                </div>
                <div class="role-right"><img v-cdnimg class="right-icon" src="/Account/images/signin/enterIcon.png" alt=""></div>
            </div>
        </div>
    </div>
    <div v-show="showStatus == '角色'">
        <div class="title">请选择登录身份</div>
        <!-- 选择登录身份区域 -->
        <div class="role-area">
            <div class="role" v-for="(item,index) in roleList.roles" @click="clickRole(item)" :key="index">
                <div class="role-left">
                    <div>
                        <div class="roleName">{{item.rolename}}</div>
                    </div>
                    <div class="platform">
                        <div v-show="item.platformname" class="platform-name">{{item.platformname}}</div>
                        <div v-show="roleList.showname" class="userName">{{roleList.showname}}</div>
                        <div v-show="item.zonename" class="detail-name">区域名称：{{item.zonename}}</div>
                        <div v-show="item.schoolname" class="detail-name">学校名称：{{item.schoolname}}</div>
                    </div>
                </div>
                <div class="role-right"><img v-cdnimg class="right-icon" src="/Account/images/signin/enterIcon.png" alt=""></div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {BING_ACCOUNT_SESSION, NEW_BING_ACCOUNT_SESSION, GET_ACCOUNT_ROLE} from '@/http/api.js'
  export default {
    name: "ChooseRole",
    props: ['config','previewConfig','Mode'],
    // props: ['config','previewConfig','Mode','roleList'],
    data() {
      return {
          showStatus:'',   // 目前展示内容
          accountList:[],      // 账号列表
          roleList:[],         // 角色列表
        // roleList:[
        //     {roleName:'系统管理员',platform:'ForClass 知慧云',userName:'贺云清'},
        //     {roleName:'学校管理员',platform:'ForClass 知慧云',userName:'贺云清'},
        //     {roleName:'区域管理员',platform:'ForClass 知慧云',userName:'贺云清'},
        //     {roleName:'教师',platform:'ForClass 知慧云',userName:'贺云清'},
        //     {roleName:'教师',platform:'ForClass 知慧云',userName:'贺云清'},
        //     {roleName:'教师',platform:'ForClass 知慧云',userName:'贺云清'},
        //     {roleName:'教师',platform:'ForClass 知慧云',userName:'贺云清'},
        // ]
      }
    },
    watch:{
        roleList:{
            handler(newVal, oldVal){
                // console.log("new", newVal);
                // console.log("old", oldVal);
                if(this.isValid(newVal.roles)){
                    // console.log("角色的长度",newVal.roles.length);

                    // if(newVal.roles.length == 1){    // 如果只有一个角色，就直接跳转
                    //     this.clickRole(newVal.roles[0])  // 只有一个用户时，把对应的用户信息保存下来
                    // }
                }
            },
            immediate:true
        }
    },
    computed:{
        platform() {
            return this.$store.state.platform;
        },
    },
    methods: {
        logOut() {
            this.$message.error('未获取到角色');

            setTimeout(() => {
                sessionStorage.removeItem("account");
                this.$store.commit({ type: 'UPDATE_ACCOUNT', account: null });
                this.setCookie('account', null, { expires: -1, domain: this.getDomain() });
                location.reload();
            }, 1000);
        },
        updateLocalUserInfo(item){     // 选择角色之后需要替换一下保存在cookie和sessionStorage里的信息
            // console.log('需要更新的本地的信息',item)
            // 更新sessionStorage里的信息
            let sessionAccount = JSON.parse(sessionStorage.getItem('account'))
            sessionAccount.role = item.rolename
            sessionStorage.setItem('account', JSON.stringify(sessionAccount));
            // 更新cookie信息
            let cookieAccount = sessionAccount;
            try {
                cookieAccount = JSON.parse(this.Cookies.get('account'))
            } catch (error) {
                console.error(error);
            }
            cookieAccount.classidx = item.classid
            cookieAccount.classname = item.classname || ''
            cookieAccount.role = item.rolename
            cookieAccount.roleidx = item.roleid
            cookieAccount.schoolidx = item.schoolid
            cookieAccount.schoolname = item.schoolname || ''
            cookieAccount.zoneidx = item.zoneid
            cookieAccount.zonename = item.zonename
            // console.log("cookie信息",cookieAccount)
            this.$store.commit({
                type: 'UPDATE_ACCOUNT',
                account: cookieAccount
            })
            // sessionStorage['account'].role = item.rolename || ''
            // 更新cookie里的信息

        },
        getRoleList(item){     // 获取角色列表
            // console.log("角色列表",item);
            // let param = {
            //     session:this.getSession(),
            //     hostName:location.hostname,
            //     page:0
            // }
            // if (param.hostName == "localhost") {
            //     param.hostName = process.env.VUE_APP_HOSTNAME;
            // }

            let param = this.getAccountRoleParam();
            GET_ACCOUNT_ROLE(param).then((res)=>{
                if(this.isValidArray(res.data.result)){
                    let tempList = JSON.parse(JSON.stringify(res.data.result[0]))
                    tempList.roles = this.handleRepeatRole(res.data.result[0].roles)
                    if(tempList.roles.length == 1){   // 如果只有一个角色，就直接跳转
                        this.clickRole(tempList.roles[0])  // 只有一个用户时，把对应的用户信息保存下来
                    }else{
                        this.roleList = tempList
                        this.showStatus = '角色'    // 显示选择角色列表
                    }
                }else{
                    this.logOut();
                }
                // this.$emit('showChooseRole',res)   
            })
        },
        getBindingRoleList(item){     // 微信扫码绑定账号之后直接获取角色列表
            console.log("绑定微信的角色列表",item);
            // let param = {
            //     session:item[0].session,
            //     hostName:location.hostname,
            //     page:0
            // }
            // if (param.hostName == "localhost") {
            //     param.hostName = process.env.VUE_APP_HOSTNAME;
            // }

            let param = this.getAccountRoleParam();
            GET_ACCOUNT_ROLE(param).then((res)=>{
                if(this.isValidArray(res.data.result)){
                    let tempList = JSON.parse(JSON.stringify(res.data.result[0]))
                    tempList.roles = this.handleRepeatRole(res.data.result[0].roles)
                    if(tempList.roles.length == 1){   // 如果只有一个角色，就直接跳转
                        this.clickRole(tempList.roles[0], item[0].session)  // 只有一个用户时，把对应的用户信息保存下来
                    }else{
                        this.roleList = tempList
                        this.showStatus = '角色'    // 显示选择角色列表
                    }
                }else{
                    this.logOut();
                }
                // this.$emit('showChooseRole',res)   
            })
        },
        clickAccount(item){   // 点击某个账号
            // debugger
            // console.log("点击了某个账号",item);
            let account = item
            let session = account.Session || account.Name     // Name是扫码登录成功的时候返回的用户session放在Name变量里了，相关接口GetWeixinAccountBystringUnionId
            let info = {
                "userid": account.UserID || account.userid,
                "session": account.Session || account.Name || account.session,
                "loginname": account.LoginName || account.loginname,
                "realname": account.RealName || account.realname,
                "account": account.LoginName || account.loginname, //showname是数据库返回的账户名（优先真实姓名，账户名次之）
                "role": account.role,
                "coin": account.Coin || account.coin,
                "level": account.level,
                "avatar": account.Avatar
            };
            //console.log("当前账号的session信息",session);
            this.saveAccount(info)    // util.js里的方法，保存信息到本地
            this.getRoleList(item)    // 点击账号之后获取对应的角色
        },
        clickRole(item, session){
            // console.log("当前点击项的信息",item);
            let param = {
                session: session || this.getSession(),
                platformId:item.platformid,
                roleId:item.roleid,
                zoneId:item.zoneid,
                schoolId:item.schoolid,
            }
            // 后台会换session，前端不用动
            NEW_BING_ACCOUNT_SESSION(this.$qs.stringify(param)).then((res)=>{
                // console.log("换绑的session",res);
                let result = res.data
                if(result.ReturnCode == 1){
                    this.updateLocalUserInfo(item)
                    this.$message({
                        message: '登录成功',
                        type: 'success'
                    });
                    let redirectUri = this.$route.query.redirecturi
                    let homeUrl = JSON.parse(localStorage['platform']).NavList[0].Url

                    this.redirectUrl(item)    // 登录成功就跳转网页

                    // if(this.isValid(redirectUri) && redirectUri!='' || this.isValid(homeUrl) && homeUrl !=''){
                    //     this.redirectUrl(item)    // 登录成功就跳转网页
                    // }else{
                    //     // console.log("当前账号的Session信息",this.getSession());
                    // }

                }else{
                    this.$message('请求失败')
                }
            })
        },
        redirectUrl(item){   // 跳转网页
            // this.updateLocalUserInfo(item)   // 更新cookie和session里的信息
            let redirectUri = this.$route.query.redirecturi

            let sepList = [       // 课程中心的这几个特殊处理
                'test-kc.forclass.net',
                'kc.forclass.net',
                'test-kc-hg.forclass.net',
                'kc-hg.forclass.net',
                'test-kc-jks.forclass.net',
                'kc-jks.forclass.net',
                'pbl.esph.com.cn',
                'test.course.lh.forclass.net',
                'course.lh.forclass.net',
                'test-kc.k12smartedu.com',
                'kc.k12smartedu.com',
            ]

            sepList.forEach((item)=>{
                if(redirectUri && redirectUri.indexOf(item) != -1){
                    let allQuery = this.$route.query
                    let tempQuery = Object.assign({},allQuery)
                    if(this.isValid(tempQuery.redirecturi)){
                        delete tempQuery.redirecturi
                    }
                    if(this.isValid(tempQuery.logout)){
                        delete tempQuery.logout
                    }
                    if(JSON.stringify(tempQuery) != '{}'){
                        const url = this.$route.query.redirecturi;
                        const params = this.$qs.stringify(tempQuery);
                        redirectUri = url.includes("?") ? url + "&" + params : url + "?" + params
                    }else{
                        redirectUri = this.$route.query.redirecturi
                    }
                }
            })

            let platRedirectUrl = this.getNavigationUrl('account.redirecturi')

            // 联想不跳转 redirectUri 【原因：由于联想角色权限限制，有可能用户从管理员网页退出登入教师，会造成页面提示语异常】
            let platform = this.$store.state.platform;
            let platformNum = platform.PlatformSystemNumber; // 平台编号
            let hostname = location.hostname;
            if (hostname == "educenter-lenovo.forclass.net" || hostname == "educenter.lenovo.forclass.net" || hostname == 'localhost') {
                location.href = "/Account/LenovoModuleSelect";
            } else if (platformNum == "000040" || platformNum == "000041") {
                if(this.isValid(platRedirectUrl) && platRedirectUrl != '') {
                    location.href = platRedirectUrl
                } else {
                    let homeUrl = this.getNavigationUrl('home')
                    location.href = homeUrl
                }
            }else{
                if(this.isValid(redirectUri) && redirectUri != "") {
                    location.href = redirectUri
                } else if(this.isValid(platRedirectUrl) && platRedirectUrl != '') {
                    location.href = platRedirectUrl
                } else {
                    let homeUrl = this.getNavigationUrl('home')
                    location.href = homeUrl
                }
            }
        },
        handleRepeatRole(list){      // 去除重复角色
            let tempList = []
            for(let i=0 ; i<list.length; i++){
                let uniqueFlag = true     // 改角色是否和之前的列表里有重复，是否是独一无二的
                tempList.forEach((item)=>{
                    if( item.platformid == list[i].platformid &&
                        item.zoneid == list[i].zoneid &&
                        item.schoolid == list[i].schoolid &&
                        item.roleid == list[i].roleid
                    ){
                        uniqueFlag = false // 说明有重复的角色
                    }
                })
                if(uniqueFlag == true){
                    tempList.push(list[i])
                }
            }
            let finalList = []   
            tempList.forEach((item)=>{
                if(item.rolename != '家长'){
                    finalList.push(item)
                }
            })
            // 联想平台下需要把（所有的家长角色给隐藏）
            if(this.$store.state.platform.PlatformSystemNumber == '000040' || this.$store.state.platform.PlatformSystemNumber == '000041'){      // 联想和联想实验室个人中心（平台编号000040 || 000041）预发布（000040 || 000041）暂时只显示前两个
                return finalList
            }else{   // 非联想平台不隐藏家长
                return tempList
            }
        }
    },
    created(){
    },
    mounted(){
        // console.log("导航栏的参数",this.$route.query);
        this.$bus.$on("chooseAccount",(res)=>{
            // console.log("所有的账号信息",res);
            this.accountList = res     // 把账户列表显示出来

            // 如果只有一个账号就不选账号了
            if(this.accountList.length ==1 ){
                this.clickAccount(this.accountList[0])
            }else{   // 不止一个账号时才显示选择账号的页面
                this.showStatus = '账号'
            }
        })
        // this.$bus.$on("chooseBindingRole",(res)=>{
        //     // 因为微信刚刚绑定这一个账号
        //     this.showStatus = '角色'
        //     this.getBindingRoleList(res)
        // })
    }
  }
</script>

<style lang="scss" scoped>
.choose-role{
    width: 100%;
    height: 100px;
    // background-color: azure;
    .title {
        font-size: 16px;
        font-weight: 550;
        color: #1D2129;
        padding-bottom: 15.5px;
        border-bottom: 2px solid #F2F3F5;
    }
    .role-area{
        height: 378px;
        overflow: hidden;
        overflow-y: auto;
        .role{
            width: 100%;
            display: flex;
            justify-content: space-between;
            // margin-top: 16px;
            // padding-bottom: 16px;
            padding: 16px 0 16px 0;
            border-bottom: 1.5px dashed #e1e2e4;
            // border-bottom: 1.5px dashed #E5E6EB;
            // border-bottom: 1.5px dashed #c0c4cc;
            cursor: pointer;
            .role-left{
                display: flex;
                .roleName {     // 角色名称
                    display: flex;
                    font-size: 13px;
                    color: #00AC98;
                    // width: 72px;
                    padding: 0 3px;
                    height: 24px;
                    box-sizing: border-box;
                    background-color: #ebfbf9;
                    border-radius: 2px;
                    align-items: center;
                    justify-content: center;
                }           // 平台信息
                .platform {
                    margin-left: 16px;
                    // display: flex;
                    // flex-direction: column;
                    justify-content: space-between;
                    font-size: 14px;
                    color: #1D2129;
                    .platform-name {
                        min-height: 19px;
                    }
                    .userName {
                        color: #4E5969;
                        margin-top: 4px;
                        height: 19px;
                    }
                    .detail-name {
                        color: #4E5969;
                        margin-top: 4px;
                        // height: 19px;
                    }
                    .platform-option{
                        margin-right: 30px;
                    }
                }
            }
            .role-right{
                display: flex;
                align-items: center;
                .right-icon {
                    width: 22px;
                    height: auto;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .title{
        width: 90%;
    }
    .role-area{
        .role{
            width: 90% !important;
            .platform{
                flex: 1;
            }
        }
    }
}

</style>