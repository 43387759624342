<template>
  <div class="container" :style="{ '--fbcolor': fbcolor }">
    <div class="title">
      <div class="navigation">
        <div class="nav-pane nav-selected">
          <div class="nav-pane-title">教师</div>
          <svg-icon
            class="nav-pane-icon"
            iconName="lenovo-tab-icon"
            :Mode="Mode"
            :config="config"
            :previewConfig="previewConfig"
          ></svg-icon>
        </div>
      </div>
      <div class="title-right" @click="switchToLogin">
        <div :style="{ color: `${specialTextColor} !important` }">登录</div>
        <svg-icon
          iconName="back-icon"
          :Mode="Mode"
          :config="config"
          :previewConfig="previewConfig"
        ></svg-icon>
      </div>
    </div>
    <div class="form">
      <el-form ref="form" :model="form" :rules="rules">
        <el-form-item prop="phoneNum">
          <el-input v-model="form.phoneNum" placeholder="手机"></el-input>
        </el-form-item>
        <div class="form-wrap">
          <el-form-item prop="numCode">
            <el-input
              v-model="form.numCode"
              placeholder="图形验证码"
            ></el-input>
          </el-form-item>
          <verify-code
            class="pright"
            ref="verifyCode"
            :verifyCode.sync="form.numCode"
          ></verify-code>
        </div>
        <div class="form-wrap">
          <el-form-item prop="phoneCode">
            <el-input
              v-model="form.phoneCode"
              placeholder="短信验证码"
            ></el-input>
          </el-form-item>
          <debounce-code
            class="pright"
            tips="获取短信验证码"
            :loading.sync="phoneLoading"
            @click.native="phoneSend"
          ></debounce-code>
        </div>
        <el-form-item prop="password">
          <el-input
            class="pwd-input"
            :type="pwdType"
            v-model="form.password"
            placeholder="密码"
            autocomplete="new-password"
          ></el-input>
          <i
            class="pwd-show"
            :class="{ active: pwdType == 'text' }"
            @click="changePwdShow"
          ></i>
        </el-form-item>
        <el-form-item prop="invitateCode">
          <el-input v-model="form.invitateCode" placeholder="邀请码"></el-input>
        </el-form-item>
      </el-form>
      <!-- 服务条款 -->
      <div class="clause">
        <el-checkbox v-model="isAgree">我已阅读并同意</el-checkbox>
        <div class="clause-content" @click="showDialog = true">服务条款</div>
      </div>
      <!-- 提交按钮 -->
      <div
        class="signup-btn"
        @click="clickSignUpBtn"
        :style="{ 'background-color': submitBgColor }"
      >
        立即注册
      </div>
      <!-- 服务条款 -->
      <service-policy
        name="普通平台"
        :showDialog="showDialog"
        @closeServicePolicyDialog="showDialog = false"
      ></service-policy>
    </div>
  </div>
</template>

<script>
import SvgIcon from "./SvgIcon.vue";
import ServicePolicy from "./ServicePolicy.vue";
import VerifyCode from "@/views/Setting/Widgets/VerifyCode.vue";
import DebounceCode from "@/views/Setting/Widgets/DebounceCode.vue";

import {
  VERIFICATION_SMS,
  AccountPhoneRegisterByInvitecode,
} from "@/http/api.js";
export default {
  data() {
    return {
      form: {
        phoneNum: "",
        numCode: "",
        phoneCode: "",
        password: "",
        invitateCode: "",
      },
      rules: {
        phoneNum: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur",
          },
          {
            pattern: /^1[3-9]\d{9}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        numCode: [
          {
            required: true,
            message: "请输入图形验证码",
            trigger: "blur",
          },
        ],
        phoneCode: [
          {
            required: true,
            message: "请输入短信验证码",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
          {
            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d|.*\W).{8,}$/,
            message:
              "大写字母、小写字母、数字、特殊符号至少包含3种，且长度≥8位",
            trigger: "blur",
          },
        ],
        invitateCode: [
          {
            required: true,
            message: "请输入邀请码",
            trigger: "blur",
          },
        ],
      },
      phoneLoading: false,
      pwdType: "password",
      isAgree: false,
      showDialog: false,
    };
  },
  props: ["config", "previewConfig", "Mode", "bindEnterFlag"],
  components: {
    SvgIcon,
    VerifyCode,
    DebounceCode,
    ServicePolicy,
  },
  computed: {
    fbcolor() {
      if (this.Mode == "preview") {
        return this.previewConfig.colorConfig.inputBoxSelect;
      } else {
        return this.config.colorConfig.inputBoxSelect;
      }
    },
    specialTextColor() {
      if (this.Mode == "preview") {
        return this.previewConfig.colorConfig.otherText;
      } else {
        return this.config.colorConfig.otherText;
      }
    },
    submitBgColor() {
      if (this.Mode == "preview") {
        return this.previewConfig.colorConfig.loginButton;
      } else {
        return this.config.colorConfig.loginButton;
      }
    },
  },
  watch: {
    bindEnterFlag(newVal) {
      if (newVal == "手机号邀请码注册") {
        this.updateCode();
        document.onkeydown = (event) => {
          if (event.key == "Enter") {
            this.clickSignUpBtn();
          }
        };
      }
    },
  },
  methods: {
    updateCode() {
      this.$refs.verifyCode.updateCode();
    },
    switchToLogin() {
      // 初始化表单
      this.$refs.form.resetFields();
      this.phoneLoading = false;
      this.pwdType = "password";
      this.isAgree = false;
      this.showDialog = false;
      this.$emit("switchToLogin");
    },
    phoneSend() {
      if (this.phoneLoading) return;

      let validCount = 0;
      let totalCount = 0;
      this.$refs.form.validateField(["phoneNum", "numCode"], (res) => {
        totalCount++;
        if (!res) validCount++;

        if (validCount == 2) {
          const body = {
            signName: "Default",
            template: "Common",
            phoneNumber: this.form.phoneNum,
            cloudServiceProvider: 2,
          };
          const codeId = this.$refs.verifyCode.stamp;
          const code = this.form.numCode;
          VERIFICATION_SMS(body, codeId, code).then((result) => {
            const data = result.data;
            if (data.status.code == 200) {
              this.phoneLoading = true;
              this.form.phoneCode = "";
            } else {
              const message = data.status.message;
              const match = message.match(/Invalid Operation:"(.*)"/);
              const result = match ? match[1] : message;
              this.$message.error(result);
              this.updateCode();
            }
          });
        } else if (totalCount == 2) {
          this.phoneLoading = false;
          this.updateCode();
        }
      });
    },
    changePwdShow() {
      this.pwdType = this.pwdType == "password" ? "text" : "password";
    },
    clickSignUpBtn() {
      this.$refs.form.validate((valid) => {
        if (!valid) return;
        if (!this.isAgree) return this.$message.error("请阅读并同意服务条款");

        const param = {
          account: this.form.phoneNum,
          pwd: this.form.password,
          invitecode: this.form.invitateCode,
          code: this.form.phoneCode,
        };
        AccountPhoneRegisterByInvitecode(this.$qs.stringify(param)).then(
          (res) => {
            const result = res.data;
            if (this.isValidArray(result.result)) {
              this.$message.success("注册成功！");
              this.$emit("switchToLogin");
            } else {
              this.updateCode();
              this.form.phoneCode = "";

              let msg = result.ReturnText;
              if (msg.includes("ErrCode:101")) {
                msg = "抱歉，您的手机号注册过，请勿重复注册！";
              } else {
                const match = msg.match(/Invalid Operation:"(.*)"/);
                msg = match ? match[1] : msg;
              }
              this.$message.error(msg);
            }
          }
        );
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/mixin.scss";
@include resetCheckbox(); // 清除原生input样式

$errorColor: #f56c6c;

.container {
  .title {
    font-size: 16px;
    font-weight: 500;
    color: #1d2129;
    padding-bottom: 17px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .navigation {
      display: flex;

      .nav-pane {
        width: 32px;
        height: 38px;
        margin-right: 34px;
        font-size: 14px;
        color: #6b7785;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        .nav-pane-title {
          height: 24px;
          display: flex;
          align-items: flex-end;
        }
        .nav-pane-icon {
          width: 34px;
          height: 10px;
        }
      }
      .nav-selected {
        font-size: 16px;
        font-weight: 600;
        color: #1d2129;
      }
    }

    .title-right {
      font-size: 14px;
      font-weight: 500;
      color: #ff6a00;
      cursor: pointer;
      display: flex;
      margin-bottom: 7px;
    }
  }

  .form {
    .el-form-item {
      margin-bottom: 16px;

      &.is-error {
        ::v-deep .el-input__inner {
          border-color: $errorColor;
          &:focus {
            border-color: $errorColor;
          }
        }
      }

      ::v-deep .el-input__inner {
        height: 38px;
        line-height: 38px;
        padding: 0 8px;
        border: none;
        border-radius: 0;
        font-size: 17px;
        border-bottom: 2px solid #f2f3f5;

        &:focus {
          border-color: var(--fbcolor);
        }

        &::placeholder {
          font-size: 14px;
        }
      }
    }

    .form-wrap {
      position: relative;

      .pright {
        position: absolute;
        top: -2px;
        right: 0;
      }

      ::v-deep .el-input__inner {
        padding-right: 130px;
      }
    }

    .pwd-show {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      display: block;
      cursor: pointer;
      user-select: none;
      background-size: 100% 100%;
      background-image: url("~@Account/images/signin/hidePwd.png");

      &.active {
        background-image: url("~@Account/images/signin/showPwd.png");
      }
    }

    .pwd-input {
      ::v-deep .el-input__inner {
        padding-right: 30px;
      }
    }

    .clause {
      margin-top: 24px;
      color: #4e5969;
      font-size: 13px;
      line-height: 21px;
      display: flex;
      align-items: center;

      .el-checkbox.is-checked {
        ::v-deep .el-checkbox__inner {
          background-color: #ff6a00;
          border-color: #ff6a00;
        }

        ::v-deep .el-checkbox__label {
          color: inherit;
        }
      }

      .clause-content {
        color: #0099ff;
        cursor: pointer;
        margin-left: 8px;
        user-select: none;
      }
    }

    .signup-btn {
      margin-top: 32px;
      height: 40px;
      background-color: #ff6a00;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      cursor: pointer;
      user-select: none;
      font-size: 16px;
      font-weight: 500;
    }
  }
}
</style>