<template>
<div class="login-container">
    <div v-show="status == 'login'">
        <!-- 导航区域 -->
        <div class="navigation">
            <div v-show="!isSystemSignUp" :class="{ 'nav-pane': true,  'nav-selected': item.modeCode == currentTag }" @click="clickNav(item.modeCode)" v-for="item in navList" :key="item.modeCode">
                <div class="nav-pane-title">{{ item.modeName }}</div>
                <svg-icon iconName="tab-icon" v-if="item.modeCode == currentTag" :config="config" :previewConfig="previewConfig" :Mode="Mode"></svg-icon>
            </div>
        </div>
        <!-- 下方输入表单的区域 -->
        <div class="form-area">
            <!-- 账号登录 / 系统创建 -->
            <account-login 
                ref="account-login"
                :previewConfig="previewConfig" 
                :Mode="Mode"
                :isSecondLogin="isSecondLogin" 
                :bindEnterFlag="bindEnterFlag"
                :config="config" 
                :isClient="isClient"
                :lenovoUserId="lenovoUserId"
                :lenovoLoginName="lenovoLoginName"
                v-if="currentTag == 'AccountLogin'" 
                @showChooseRole="showChooseRole"
                @forgetPwd="forgetPwd" 
                @signUp="signUp"
                @onSystemSignUp="onSystemSignUp">
            </account-login>
            <!-- 口令登录 -->
            <token-login 
                :previewConfig="previewConfig" 
                :Mode="Mode"
                :bindEnterFlag="bindEnterFlag"
                @showChooseRole="showChooseRole"
                :isSecondLogin="isSecondLogin"
                :config="config" 
                v-if="currentTag == 'CommandLogin'">
            </token-login>
            <!-- 短信登录 -->
            <message-login 
                :previewConfig="previewConfig" 
                :Mode="Mode"
                :bindEnterFlag="bindEnterFlag"
                :isSecondLogin="isSecondLogin"
                @showChooseRole="showChooseRole"
                :config="config" 
                v-if="currentTag == 'ShortMessageLogin'">
            </message-login>
            <!-- 邮箱登录 -->
            <email-login 
                :previewConfig="previewConfig" 
                :Mode="Mode"
                :bindEnterFlag="bindEnterFlag"
                :isSecondLogin="isSecondLogin" 
                @showChooseRole="showChooseRole"
                :config="config" 
                v-if="currentTag == 'EmailLogin'">
            </email-login>
        </div>
    </div>
    <div v-show="status == 'special'" class="others">
        <!-- 选择角色 -->
        <choose-role 
            :roleList="roleList" 
            :config="config" 
            v-show="isChooseRole" 
            :bindEnterFlag="bindEnterFlag"
            :previewConfig="previewConfig" 
            :Mode="Mode">
        </choose-role>
        <!-- 忘记密码 -->
        <forget-pwd 
            :config="config"
            v-if="isForgetPwd" 
            :bindEnterFlag="bindEnterFlag"
            @switchToLogin="switchToLogin" 
            :previewConfig="previewConfig" 
            :Mode="Mode">
        </forget-pwd>
        <!-- 立即注册 -->
        <sign-up 
            ref="sign-up"
            :config="config" 
            v-if="isSignUp" 
            :bindEnterFlag="bindEnterFlag"
            @switchToLogin="switchToLogin" 
            :previewConfig="previewConfig" 
            :Mode="Mode">
        </sign-up>
        <invitation-sign-up
            v-if="isInvitationSignUp"
            :config="config"
            :bindEnterFlag="bindEnterFlag"
            @switchToLogin="switchToLogin"
            :previewConfig="previewConfig" 
            :Mode="Mode">
        </invitation-sign-up>
        <!-- 联想项目的立即注册（较为特殊单独处理） -->
        <lenovo-signup 
            ref="lenovo-signup"
            :config="config" 
            :isClient="isClient"
            v-if="isLenovoSignUp" 
            :bindEnterFlag="bindEnterFlag"
            @switchToLogin="switchToLogin"
            :previewConfig="previewConfig" 
            :Mode="Mode">
        </lenovo-signup>
    </div>
</div>
</template>

<script>
import { 
    AUTHORIZATION_LENOVO,
    AUTHORIZATION_LENOVO_RETURNID
} from '@/http/api.js'
import AccountLogin from '@/components/signin/Widgets/AccountLogin'
import TokenLogin from '@/components/signin/Widgets/TokenLogin'
import MessageLogin from '@/components/signin/Widgets/MessageLogin'
import EmailLogin from '@/components/signin/Widgets/EmailLogin'
import ChooseRole from '@/components/signin/Widgets/ChooseRole'
import ForgetPwd from '@/components/signin/Widgets/ForgetPwd'
import SignUp from '@/components/signin/Widgets/SignUp.vue'
import InvitationSignUp from '@/components/signin/Widgets/InvitationSignUp.vue'
import LenovoSignup from '@/components/signin/Widgets/LenovoSignUp.vue'
import SvgIcon from './Widgets/SvgIcon.vue'
export default {
    components:{
        AccountLogin, 
        TokenLogin, 
        MessageLogin, 
        EmailLogin,
        ChooseRole, 
        ForgetPwd, 
        SignUp, 
        SvgIcon, 
        LenovoSignup,
        InvitationSignUp
    },
    props:['config', 'previewConfig', 'Mode', 'type', 'isLenovoPlatform'],
    data() {
        return {
            status: 'login',            // 是普通的登录状态login 还是 选择角色、忘记密码、立即注册等特殊状态special
            isChooseRole: false,        // 是否是选择角色
            isForgetPwd: false,         // 是否是忘记密码
            isSignUp: false,            // 是否是普通平台的立即注册
            isLenovoSignUp: false,       // 是否是联想项目的注册页面
            isInvitationSignUp: false,   // 是否是邀请注册
            isSecondLogin: false,       // 判断是否是二次登录
            currentTag: 'AccountLogin',  // 保存当前所点击的导航
            bindEnterFlag: 'AccountLogin',     // 用于实现绑定Enter事件
            nav: [   // 导航区域
                { title: '账号登录', flag: true , id: 0 },
                { title: '口令登录', flag: false, id: 1 },
                { title: '短信登录', flag: false, id: 2 },
                { title: '邮箱登录', flag: false, id: 3 },
            ],
            roleList: [],                // 初次登录成功之后获取的角色列表

            isSystemSignUp: false, // 是否系统创建
            isClient: false, // 是否关联账号
            lenovoUserId: '', // 联想userid
            lenovoLoginName: '', // 联想账号
        }
    },
    computed:{
        navList(){      // 导航条内容
            if(this.Mode == 'preview'){   // 预览模式
                let firstOption = this.previewConfig.loginModeConfigs[0]     // 获取第一项，第一项是扫码就显示扫码登录，不是扫码就显示第一项为默认选中项
                if(firstOption.modeName == '扫码登录'){
                    if(firstOption.platformEnableStatus){    // 如果第一项是扫码登录且平台的扫码登录是打开的，则默认展示扫码登录的页面
                        this.$emit("showQRcode")
                    }else{   // 如果扫码登录是第一项但是没有打开，则把 扫码和PC小电脑的图标都隐藏不显示
                        this.$emit("hideTopRightCornerIcon")
                    }
                }
                var tempList = JSON.parse(JSON.stringify(this.previewConfig.loginModeConfigs))
                var QRcodeIndex = null;
                tempList.forEach((item,index)=>{
                    if(item.modeName == '扫码登录'){
                        if(item.platformEnableStatus == false){   // 扫码登录这一项平台登录状态是关闭的，所以就把右上角图标都隐藏
                            this.$emit("hideTopRightCornerIcon")
                        }else{
                            this.$emit("showTopRightCornerIcon")
                        }
                        QRcodeIndex = index
                    }
                })
                let QRCodeOption = {}    // 记录被删除的哪一项
                if(QRcodeIndex != null){   // 如果存在扫码登录哪一项
                    QRCodeOption = tempList.splice(QRcodeIndex,1)    // 删除扫码登录的一项
                }

                if(this.type == 'platform'){   // 平台登录
                    let list = []    // 空数组保存最后显示的结果
                    tempList.forEach((item,index)=>{
                        if(item.platformEnableStatus == true){   // 如果平台登录是打开的
                            list.push(item)
                        }
                    })
                    tempList = list
                }else if(this.type == 'manage'){  // 管理模块登录
                    let list = []    
                    tempList.forEach((item,index)=>{
                        if(item.adminmoduleEnableStatus == true){  
                            list.push(item)
                        }
                    })
                    tempList = list
                }else if(this.type == 'account'){  // 个人中心登录
                    let list = []    
                    tempList.forEach((item,index)=>{
                        if(item.personalCenterEnableStatus == true){   
                            list.push(item)
                        }
                    })
                    tempList = list
                }else{
                    this.$message.error('加载失败，请重试');
                }
                if(tempList[0]?.modeCode){
                    this.currentTag = tempList[0].modeCode     // 下面内容显示成默认选中的哪一项
                }else{
                    this.currentTag = ""     // 下面内容显示成默认选中的哪一项
                }
                return tempList
            }else{   // 正常模式
                let firstOption = this.config.loginModeConfigs[0]     // 获取第一项，第一项是扫码就显示扫码登录，不是扫码就显示第一项为默认选中项
                if(firstOption.modeName == '扫码登录'){
                    if(firstOption.platformEnableStatus){    // 如果第一项是扫码登录且平台的扫码登录是打开的，则默认展示扫码登录的页面
                        this.$emit("showQRcode")
                    }else{   // 如果扫码登录是第一项但是没有打开，则把 扫码和PC小电脑的图标都隐藏不显示
                        this.$emit("hideTopRightCornerIcon")
                    }
                }
                var tempList = JSON.parse(JSON.stringify(this.config.loginModeConfigs))
                var QRcodeIndex = null;
                tempList.forEach((item,index)=>{
                    if(item.modeName == '扫码登录'){
                        if(item.platformEnableStatus == false){   // 扫码登录这一项平台登录状态是关闭的，所以就把右上角图标都隐藏
                            this.$emit("hideTopRightCornerIcon")
                        }else{
                            this.$emit("showTopRightCornerIcon")
                        }
                        QRcodeIndex = index
                    }
                })
                let QRCodeOption = {}    // 记录被删除的哪一项
                if(QRcodeIndex != null){   // 如果存在扫码登录哪一项
                    QRCodeOption = tempList.splice(QRcodeIndex,1)    // 删除扫码登录的一项
                }

                if(this.type == 'platform'){   // 平台登录
                    let list = []    // 空数组保存最后显示的结果
                    tempList.forEach((item,index)=>{
                        if(item.platformEnableStatus == true){   // 如果平台登录是打开的
                            list.push(item)
                        }
                    })
                    tempList = list
                }else if(this.type == 'manage'){  // 管理模块登录
                    let list = []    
                    tempList.forEach((item,index)=>{
                        if(item.adminmoduleEnableStatus == true){  
                            list.push(item)
                        }
                    })
                    tempList = list
                }else if(this.type == 'account'){  // 个人中心登录
                    let list = []    
                    tempList.forEach((item,index)=>{
                        if(item.personalCenterEnableStatus == true){   
                            list.push(item)
                        }
                    })
                    tempList = list
                }else{
                    this.$message.error('加载失败，请重试');
                }

                if(tempList[0]?.modeCode){
                    this.currentTag = tempList[0].modeCode     // 下面内容显示成默认选中的哪一项
                }else{
                    this.currentTag = ""     // 下面内容显示成默认选中的哪一项
                }
                return tempList
            }
        }
    },
    methods:{
        clickNav(modeCode){    // 点击
            this.currentTag = modeCode     // 确定当前点击的是哪个标签页
            this.bindEnterFlag = modeCode   // 传给组件用于绑定enter事件
        },
        forgetPwd(){   // 点击-忘记密码
            this.status = 'special'
            this.isChooseRole = false
            this.bindEnterFlag = '忘记密码'
            this.isForgetPwd = true
            this.isSignUp = false
            this.isLenovoSignUp = false
            this.isInvitationSignUp = false;
        },
        signUp(){     // 点击-立即注册
            this.status = 'special'
            this.isChooseRole = false
            this.bindEnterFlag = '立即注册'
            this.isForgetPwd = false
            if(this.isLenovoPlatform == true){
                this.isLenovoSignUp = true  // 打开联想的注册方式
                this.isSignUp = false       // 关掉普通的注册方式
                this.isInvitationSignUp = false
                this.bindEnterFlag = '联想注册'
            }else if(this.$route.query.logonMode == "invite"){
                this.isLenovoSignUp = false  // 关掉联想的注册方式
                this.isSignUp = false       // 关掉普通的注册方式
                this.isInvitationSignUp = true
                this.bindEnterFlag = '手机号邀请码注册'
            }else{
                this.isLenovoSignUp = false  // 关掉联想的注册方式
                this.isSignUp = true         // 打开普通的注册方式
                this.isInvitationSignUp = false
            }
        },
        onSystemSignUp(val) {
            this.isSystemSignUp = val;
        },
        switchToLogin(){   // 切换到登录页面
            this.status = 'login'
            this.bindEnterFlag = this.currentTag
            this.$refs['account-login'].updateValidateCode()    // 更新验证码
        },
        showChooseRole(res){    // 显示选择角色的页面
            this.status = 'special'   // 隐藏掉导航部分及原来的各种登录页面
            this.isChooseRole = true
            this.bindEnterFlag = '选择角色'
            this.isForgetPwd = false
            this.isSignUp = false
            this.isLenovoSignUp = false
            this.isInvitationSignUp = false
            this.$bus.$emit("chooseAccount",res)    // 在ChooseRole.vue里面
        },
        showBindingChooseRole(res){    // 显示微信刚刚绑定账号对应的选择角色的页面
            // this.status = 'login'   // 切换到登录页面
            this.status = 'special'   // 隐藏掉导航部分及原来的各种登录页面
            this.isChooseRole = true
            this.bindEnterFlag = '选择角色'
            this.isForgetPwd = false
            this.isSignUp = false
            this.isLenovoSignUp = false
            this.isInvitationSignUp = false
            // this.$bus.$emit("chooseBindingRole",res)    // 在ChooseRole.vue里面
            this.$bus.$emit("chooseAccount",res)    // 在ChooseRole.vue里面
        },
        getLenovoUserInfoByToken(){    // 通过token换用户信息
            // console.log("联想的token信息",this.$route.query);
            let queryInfo = this.$route.query;
            if(this.isValid(queryInfo)) {
                if(queryInfo.tokentype == 'lenovo' && queryInfo.device == 'lenovowin') {
                    this.status = 'login';
                    // AUTHORIZATION_LENOVO({ cmdInfo: queryInfo.token }).then(res => {
                    //     // console.log("token换用户信息成功",res);
                    //     if(res.data.ReturnCode == 1){
                    //         if(this.isValidArray(res.data.result)){
                    //             this.showChooseRole(res.data.result)
                    //         }
                    //     }else{
                    //         this.$message.error(res.data.ReturnText);
                    //     }
                    // })
                    AUTHORIZATION_LENOVO_RETURNID({ cmdInfo: queryInfo.token }).then(res => {
                        if(res.data.ReturnCode == 1) {
                            this.isClient = true; // 隐藏二维码登录图标
                            let result = res.data.result;
                            if(this.isValidArray(result)) {
                                if(result[0].TypeId.toUpperCase() == 'LENOVOUSERID') {
                                    this.lenovoUserId = result[0].Value;
                                    this.lenovoLoginName = result[0].loginName;
                                    this.$emit('onIsClient', this.isClient);
                                } else if(result[0].TypeId.toUpperCase() == 'SESSION'){
                                    this.showChooseRole(result[0].Value);
                                }
                            }
                        } else {
                            this.$message.error(res.data.ReturnText);
                        }
                    })
                }
            }
        }
    },
    created(){
        // 处理 忘记密码
        if (this.$route.query.module == "wjmm") this.forgetPwd();
        this.getLenovoUserInfoByToken()
    },
    mounted(){

            // let redirectUri = this.$route.query.redirecturi

            // let sepList = [       // 课程中心的这几个特殊处理
            //     'test-kc.forclass.net',
            //     'kc.forclass.net',
            //     'test-kc-jks.forclass.net',
            //     'kc-jks.forclass.net',
            //     'pbl.esph.com.cn',
            //     'test.course.lh.forclass.net',
            //     'course.lh.forclass.net',
            // ]

            // sepList.forEach((item)=>{
            //     if(redirectUri && redirectUri.indexOf(item) != -1){
            //         let allQuery = this.$route.query
            //         let tempQuery = Object.assign({},allQuery)
            //         if(this.isValid(tempQuery.redirecturi)){
            //             delete tempQuery.redirecturi
            //         }
            //         if(this.isValid(tempQuery.logout)){
            //             delete tempQuery.logout
            //         }
            //         if(JSON.stringify(tempQuery) != '{}'){
            //             redirectUri = this.$route.query.redirecturi + '&' + this.$qs.stringify(tempQuery)
            //         }else{
            //             redirectUri = this.$route.query.redirecturi
            //         }
            //         console.log("目标跳转地址",redirectUri);
            //     }
            // })

        this.$bus.$on('QRCodeShowChooseRole',(res)=>{      // 二维码扫码登录成功之后，展示角色选择，同时在SignIn.vue里切换到哪个普通的登录页面，如果还在扫码登录页面无法显示出来角色选择
            // console.log("获取到了扫码二维码之后的角色信息",res);
            this.showChooseRole(res)  // 扫码成功之后切换到选择账号或角色页面
        })
        this.$bus.$on('QRCodeBindingRole',(res)=>{      // 二维码扫码初次绑定账号成功之后展示角色选择，同时在SignIn.vue里切换到哪个普通的登录页面，如果还在扫码登录页面无法显示出来角色选择
            // console.log("获取到了扫码二维码之后的角色信息",res);
            this.showBindingChooseRole(res)  // 扫码成功之后切换到选择账号或角色页面
        })
        // let tempList = JSON.parse(JSON.stringify(this.previewConfig.loginModeConfigs))
        // let QRcodeIndex = null;
        // tempList.forEach((item,index)=>{
        //     console.log("打印",item,index)
        // })
    }
}
</script>

<style lang="scss" scoped>
.login-container{
    // background-color: #87ceeb54;
    .navigation {
        width: 392px;
        height: 38px;
        // background-color: aliceblue;
        display: flex;
        .nav-pane{
            width: 64px;
            height: 38px;
            margin-right: 28px;
            font-size: 14px;
            color: #6B7785;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            .nav-pane-title {
                height: 24px;
                display: flex;
                align-items: flex-end;
                width: 64px;
            }
            .nav-pane-icon{
                width: 34px;
                height: 10px;
            }
        }
        .nav-selected{
            font-size: 16px;
            font-weight: 600;
            color: #1D2129;
        }
    }
    .others{
        width: 392px;
        margin-top: 2px;
    }
}

// H5移动端
@media screen and (max-width: 768px) {
    .login-container{
        width: 100%;
        .navigation{
            display: none;
        }

        .others {
            width: 100%;
        }
    }
}
</style>