<template>
<div :style="{'--inputFocusColor':inputFocusBottomColor}">


    <!-- 普通的短信登录(非二次登录的情况) -->
    <div v-if="isSecondLogin == false"  class="message-login">

    <!-- 非二次登录的意思就是之前已经登录了，进管理模块或者个人中心切换到设备管理那块需要重新输入一下密码以验证身份 -->
    <!-- 如果不是第一次短信登录，登录成功之后可以直接进入选角色，否则显示绑定账号的页面 -->
        <div class="general-message-login" v-show="isBindPhoneToAccountView == false">
            <input v-model="phoneNum" type="text" spellcheck="false" placeholder="手机号"
            @blur="inputBlur"
            @click="inputSelected = '手机号'"
            :class="{'clear-input':true, 'message-input':true, 'input-focus':inputSelected=='手机号'}" 
            >
            <div class="number-code-container"  @click="inputSelected = '图形验证码'" :class="{'input-focus':inputSelected=='图形验证码'}">
                <input 
                v-model="numCode" 
                type="text"
                @blur="inputBlur" 
                class="clear-input general-verify-code" 
                spellcheck="false" 
                placeholder="图形验证码">
                <div class="icon-container" @click="updateValidateCode">
                    <img :src="numCodeURL" class="code-img" alt="">
                    <img v-cdnimg src="/Account/images/signin/updateIcon.png" :class="{'update-code-icon':true, 'number-animation':clickUpdateIcon}" alt="">
                </div>
            </div>
            <div :class="{'verify-code-container':true, 'input-focus':inputSelected=='短信验证码'}">
                <input v-model="verifyCode" type="text" spellcheck="false" placeholder="短信验证码"
                @blur="inputBlur"
                class="clear-input general-verify-code"
                @click="inputSelected = '短信验证码'"
                >
                <div class="get-verify-code" v-show="showText" @click="getVerifyCode" :style="{'color':`${specialTextColor} !important`}">
                    获取验证码
                </div>
                <div v-show="!showText" :class="{'update-code':true, 'update-animation':clickUpdate}" alt="">
                    <span class="countdown">{{countdown}}</span>
                    <span> 秒后可重新发送</span>
                </div>
            </div>
        </div>


        <!-- 第一次通过短信登录，需要先绑定账号 -->
        <div class="bind-account" v-show="isBindPhoneToAccountView == true">
            <input v-model="account" type="text" spellcheck="false" placeholder="账号"
            @blur="inputBlur"
            @click="inputSelected = '账号'"
            :class="{'clear-input':true, 'message-input':true, 'input-focus':inputSelected=='账号'}"
            >
            <input v-model="password" type="password" spellcheck="false" placeholder="密码"
            @blur="inputBlur"
            @click="inputSelected = '密码'"
            :class="{'clear-input':true, 'message-input':true, 'message-login-pwd':true ,'input-focus':inputSelected=='密码'}"
            >
        </div>

        <div class="tip" v-show="isBindPhoneToAccountView == true">
            首次使用，请您将当前手机号与账号绑定
        </div>

    </div>


    <!-- 二次登录 -->
    <div v-if="isSecondLogin == true" class="message-login">
        <input v-model="phoneNum" type="text" class="clear-input message-input" spellcheck="false" placeholder="手机号"
        @blur="inputBlur"
        @click="inputSelected = '二次登录手机号'"
        :class="{'input-focus':inputSelected=='二次登录手机号'}"
        >
        <div class="verify-code-container" :class="{'input-focus':inputSelected=='二次登录验证码'}">
            <input v-model="verifyCode" type="text" class="clear-input general-verify-code" spellcheck="false" placeholder="验证码"
            @click="inputSelected = '二次登录验证码'"    
            @blur="inputBlur"
            >
            <div class="get-verify-code" v-show="showText" @click="getVerifyCode" :style="{'color':`${specialTextColor} !important`}">
                获取验证码
            </div>
            <div v-show="!showText" :class="{'update-code':true, 'update-animation':clickUpdate}" alt="">
                <span class="countdown">{{countdown}}</span>
                <span> 秒后可重新发送</span>
            </div>
        </div>
    </div>

    <!-- 登录按钮 -->
    <div class="login-btn" @click="clickMessageLogin" v-show="isBindPhoneToAccountView == false" :style="{'background-color':submitBtnBackgroundColor}">
        <div class="login-text">登&nbsp;录</div>
    </div>

    <!-- 绑定按钮：首次用手机验证码登录需要先和一个账号进行绑定-->
    <div class="login-btn" @click="bindMessageLogin" v-show="isBindPhoneToAccountView == true" :style="{'background-color':submitBtnBackgroundColor}">
        <div class="login-text">绑&nbsp;定</div>
    </div>
</div>
</template>

<script>
import {
    SEND_PHONE_MESSAGE, 
    NEW_SHORT_MESSAGE_SEND,
    UNIFIED_LOGIN, 
    GET_LOGIN_SEED, 
    BIND_ACCOUNT
} from '@/http/api.js'
  export default {
    name: "MessageLogin",
    props: ['isSecondLogin','config','previewConfig','Mode','bindEnterFlag'],
    data() {
        return {
            account:'',           // 账号
            password:'',          // 密码
            phoneNum:'',          // 手机号
            verifyCode:'',        // 短信验证码
            key:'',               // 加密用的信息
            iv:'',                // 加密用的信息
            encryptedPhone:'',  // 加密之后的手机号
            encryptedCode:'',      // 加密之后的验证码
            clickUpdate:false,    // 是否点击了更新验证码 
            countdown:60,         // 倒计时
            showText:true,
            inputSelected:'',       // 被选中的输入框名字，来添加不同的底部颜色
            clickUpdateIcon:false,   // 实现点击刷新图标旋转功能
            numCode:'',            // 数字验证码
            numCodeURL:'',        // 图形验证码地址
            isBindPhoneToAccountView:false,   // 是否是第一次通过短信登录，如果是需要先将手机号和一个账号绑定
        }
    },
    watch:{
        bindEnterFlag:{
            handler:function(newVal, oldVal){
                let that = this
                if(newVal == 'ShortMessageLogin'){
                    document.onkeydown = function(event){
                        if(event.key == 'Enter'){
                            that.clickMessageLogin() 
                        }
                    }
                }
            },
            immediate: true
        }
    },
    computed:{
        submitBtnBackgroundColor(){   // 提交按钮的背景色
            if(this.Mode == 'preview'){    // 预览模式
                return this.previewConfig.colorConfig.loginButton
            }else{                       
                return this.config.colorConfig.loginButton
            }
        },
        inputFocusBottomColor() {   // 点击输入框时输入框底部的颜色
            if(this.Mode == 'preview'){    // 预览模式
                return this.previewConfig.colorConfig.inputBoxSelect
            }else{                       
                return this.config.colorConfig.inputBoxSelect
            }
        },
        specialTextColor(){     // 登录 > 的颜色
            if(this.Mode == 'preview'){    // 预览模式
                return this.previewConfig.colorConfig.otherText
            }else{                       
                return this.config.colorConfig.otherText
            }
        },
    },
    methods: {
        sendMessage(){   // 发送短信
            let param = {
                Number:this.phoneNum,
                ImageVerificoationCode:this.numCode,
                stamp:this.getSessionVal('stamp')
            }
            NEW_SHORT_MESSAGE_SEND(this.$qs.stringify(param)).then((res)=>{
                let returnCode = res.data.ReturnCode
                if(returnCode == '1'){
                    this.showText = false   // 显示倒计时
                    this.countdown = 59     // 每次点击打开时重置为59
                    let timer = setInterval(()=>{
                        this.countdown--
                        if(this.countdown <= 0){
                            clearInterval(timer)
                            this.showText = true
                        }
                    },1000)
                    this.$message.success('发送成功!');
                }else if(returnCode == '-109001'){
                    this.$message.error('图形验证码无效');
                    this.updateValidateCode();  // 更新验证码 
                }else{
                    this.$message.error('发送短信验证码失败');
                    this.updateValidateCode();  // 更新验证码 
                }
            })
        },
        getVerifyCode(){   // 点击获取短信验证码
            if(this.phoneNum == ''){
                this.$message.error('请输入手机号');
            }else if(this.numCode == ''){
                this.$message.error('请输入图形验证码');
            }else{
                var reg = /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/   // 手机号正则
                if(reg.test(this.phoneNum)){
                    this.sendMessage()    // 向手机发送验证码
                }else{
                    this.$message.error('请输入正确格式的手机号码');
                    this.updateValidateCode()  // 更新验证码 
                }

                // let param = {
                //     session:"0C88C2B040939BAE727BC626B1E",     // 警告：开发时显示写死，因为短信登陆输入二次登录的内容，所以一定要变成已经登录的用户的session
                //     phone:this.phoneNum
                // }
                // SEND_PHONE_MESSAGE(this.$qs.stringify(param)).then((res)=>{
                //     console.log("发送了短信验证码",res)
                // })
            }

            // if(this.isSecondLogin == false){  // 二次登录的时候
            //     if(this.phoneNum == ''){
            //         this.$message.error('请输入手机号');
            //     }else{
            //         let param = {
            //             session:"0C88C2B040939BAE727BC626B1E",     // 警告：开发时显示写死，因为短信登陆输入二次登录的内容，所以一定要变成已经登录的用户的session
            //             phone:this.phoneNum
            //         }
            //         SEND_PHONE_MESSAGE(this.$qs.stringify(param)).then((res)=>{
            //             console.log("发送了短信验证码",res)
            //         })
            //     }
            // }
        },
        bindAccount(){   // 绑定账户
            this.getNewSeed(this.account,this.password).then((encryptedInfo)=>{   // 获取新的加密种子
                let param = {
                    BindAccountMode:1,     // 1是绑定手机号 2是绑定邮箱账号
                    LoginName:encryptedInfo.encryptedAccount, // 加密之后的账号
                    Password:encryptedInfo.encryptedCode,  // 加密后的密码
                    number:this.encryptedPhone, // 邮箱或者手机号(加密之后的)
                    Other:{
                        stamp:this.getSessionVal('stamp')     // 加密种子GetSeed的时间戳
                    }
                }
                BIND_ACCOUNT(param).then((res)=>{
                    let data = res.data
                    if(data.ReturnCode == '1'){
                        this.$message({
                            message: '绑定成功',
                            type: 'success'
                        });
                        this.loginAfterBindAccount()   // 应该是绑定成功之后登录
                    }else{
                        this.$message.error(data.ReturnText);
                    }
                })
            })
        },
        getSeed(){     // 获取加密种子
            let identity = this.getSessionVal('stamp');   // 获取一个唯一的标志（比如时间戳）
            GET_LOGIN_SEED(this.$qs.stringify({stamp: identity})).then((response)=>{
                let data = response.data.result[0]
                this.key = data.Key
                this.iv = data.Iv
                this.encryptedPhone = this.encrypt(this.phoneNum, this.key, this.iv).replace(/\+/g, '%2B')  // 加密之后的手机号
                this.encryptedCode = this.encrypt(this.verifyCode, this.key, this.iv).replace(/\+/g, '%2B')     // 加密之后的验证码
                this.unifiedLogin()   // 调用统一登录接口
            })
        },
        async getNewSeed(account, code){    // 获取加密种子
            let identity = this.getSessionVal('stamp');
            let encryptedInfo = {encryptedAccount:'',encryptedCode:''}    // 加密之后的对象
            await GET_LOGIN_SEED(this.$qs.stringify({stamp: identity})).then((response)=>{
                let data = response.data.result[0]
                let Key = data.Key
                let Iv = data.Iv
                encryptedInfo.encryptedAccount = this.encrypt(account, Key, Iv).replace(/\+/g, '%2B') 
                encryptedInfo.encryptedCode = this.encrypt(code, Key, Iv).replace(/\+/g, '%2B')
                this.encryptedPhone = this.encrypt(this.phoneNum, Key, Iv).replace(/\+/g, '%2B')
            })
            return encryptedInfo
        },
        unifiedLogin(){   // 统一登录
            // 已加密参数
            let param = {
                UnifiedLoginMode:2,   // 2是短信登录、3是邮箱登录
                UniquelyIdentifies:this.encryptedPhone,
                Certificate:this.encryptedCode,
                Other:{
                    stamp:this.getSessionVal('stamp')
                }
            }
            UNIFIED_LOGIN(param).then((res)=>{
                let userInfo = res.data    // 返回的数据
                if(!this.isValidArray(userInfo.result)){  // 出错
                    let warningText = userInfo.ReturnText
                    let warningMessage = ''    // 警告信息
                    let errorNum = 0           // 错误次数
                    this.updateValidateCode()  // 更新验证码 
                    if(userInfo.ReturnCode == '-102015'){    // 无用户信息需要先绑定账号
                        this.isSecondLogin = false
                        this.isBindPhoneToAccountView = true   // 显示绑定账号的页面
                    }else{
                        switch (warningText) {
                            case 'ErrCode:0 DB throwed Exception.':
                                warningMessage = "网络繁忙，请稍后再试";
                                break;
                            case 'ErrCode:0 Account is not exist.':
                                warningMessage = "此用户名不存在";
                                break;
                            case 'ErrCode:0 Password is not correct':
                                errorNum++
                                let msg = "用户名或密码不正确，您还可以尝试"+ (3-errorNum) +"次"
                                if(errorNum >= 3)
                                {
                                    msg = "您的账号由于密码累计输错3次已被锁定，请十分钟后再试"
                                }
                                warningMessage = msg;
                                break;
                            case 'ErrCode:0 Account is forbidden':
                                warningMessage = "此账户已被禁用，请联系管理员";
                                break;
                            case 'ErrCode:0 Account expired':
                                warningMessage = "此账户过期，请联系管理员";
                                break;
                            case 'Invalid request':
                                warningMessage = "请勿频繁操作"
                                break;
                            case 'Too much failures':
                                warningMessage = "您的账号由于密码累计输错3次已被锁定，请十分钟后再试";
                                break;
                            case 'Already binded':
                                warningMessage = "您所登录的账户已经被其他微信号绑定，请联系管理员确认账户绑定情况。";
                                break;
                            case 'WXLogin student not allowed':
                                warningMessage = "暂不支持使用学生账户绑定微信号。";
                                break;
                            case 'ErrCode:0 not allowed':
                                warningMessage = "抱歉，您没有权限！";
                                break;
                            case 'Teachers have no classes':
                                errmsg = "该账号没有任教班级，请先任教，再扫码绑定！";
                                break;
                            //case -104:
                            //    warningMessage = "会话无效，请联系管理员";
                            //    break;
                            default:
                                warningMessage = warningText;
                                break;
                        }
                        this.verifyCode = '';
                        this.$message.error(warningMessage);
                    }
                }else{    // 用户数据返回了
                    this.$emit('showChooseRole',userInfo.result)    // 把所有的账号列表传过去，显示选账号和选角色的页面 
                }
            })
        },
        loginAfterBindAccount(){   // 绑定账号成功之后，登录然后进入选角色的页面
            this.getNewSeed(this.account,this.password).then((encryptedInfo)=>{
                let dataObj = {
                    UnifiedLoginMode:1,    // 1也可以
                    UniquelyIdentifies:encryptedInfo.encryptedAccount,
                    Certificate:encryptedInfo.encryptedCode,
                    Other:{
                        stamp:this.getSessionVal('stamp')
                    }
                }
                UNIFIED_LOGIN(dataObj).then((LoginInfo)=>{    // 加密账户名和密码调接口换取用户信息
                    let userInfo = LoginInfo.data    // 返回的数据
                    if(!this.isValidArray(userInfo.result)){  // 出错
                        let warningText = userInfo.ReturnText
                        let warningMessage = ''    // 警告信息
                        let errorNum = 0           // 错误次数
                        switch (warningText) {
                            case 'ErrCode:0 DB throwed Exception.':
                                warningMessage = "网络繁忙，请稍后再试";
                                break;
                            case 'ErrCode:0 Account is not exist.':
                                warningMessage = "此用户名不存在";
                                break;
                            case 'ErrCode:0 Password is not correct':
                                errorNum++
                                let msg = "用户名或密码不正确，您还可以尝试"+ (3-errorNum) +"次"
                                if(errorNum >= 3)
                                {
                                    msg = "您的账号由于密码累计输错3次已被锁定，请十分钟后再试"
                                }
                                warningMessage = msg;
                                break;
                            case 'ErrCode:0 Account is forbidden':
                                warningMessage = "此账户已被禁用，请联系管理员";
                                break;
                            case 'ErrCode:0 Account expired':
                                warningMessage = "此账户过期，请联系管理员";
                                break;
                            case 'Invalid request':
                                warningMessage = "请勿频繁操作"
                                break;
                            case 'Too much failures':
                                warningMessage = "您的账号由于密码累计输错3次已被锁定，请十分钟后再试";
                                break;
                            case 'Already binded':
                                warningMessage = "您所登录的账户已经被其他微信号绑定，请联系管理员确认账户绑定情况。";
                                break;
                            case 'WXLogin student not allowed':
                                warningMessage = "暂不支持使用学生账户绑定微信号。";
                                break;
                            case 'ErrCode:0 not allowed':
                                warningMessage = "抱歉，您没有权限！";
                                break;
                            case 'Teachers have no classes':
                                errmsg = "该账号没有任教班级，请先任教，再扫码绑定！";
                                break;
                            //case -104:
                            //    warningMessage = "会话无效，请联系管理员";
                            //    break;
                            default:
                                warningMessage = warningText;
                                break;
                        }
                        this.$message.error(warningMessage);
                    }else{    // 用户数据返回了
                        this.$emit('showChooseRole',userInfo.result)    // 把所有的账号列表传过去，显示选账号和选角色的页面 
                    }
                })    
            })
        },
        clickMessageLogin(){   // 点击短信登录
            var reg = /^[0-9]+$/
            if(this.phoneNum == ''){
                this.$message.error('请输入手机号');
                return
            }else if(!reg.test(this.phoneNum)){
                this.$message.error('请输入正确格式的手机号');
                return
            }else if(this.numCode == ''){
                this.$message.error('请输入图形验证码');
                return
            }else if(this.verifyCode == ''){
                this.$message.error('请输入短信验证码');
                return
            }
            this.getSeed()   // 调用登录的接口获取信息，保存登录状态，处理登录成功之后的操作
        },
        bindMessageLogin(){  // 点击绑定按钮
            if(this.account == ''){
                this.$message.error('请输入账号');
                return
            }else if(this.password == ''){
                this.$message.error('请输入密码');
                return
            }else{
                this.bindAccount()   // 调接口绑定账号
            }
        },
        inputBlur(){  // 输入框失去焦点
            this.inputSelected = ''
        },
        async updateValidateCode() {  // 更新验证码 
            this.numCode = '';    //刷新验证码时把原来的验证码置空
            let stamp = this.getSessionVal('stamp');
            if(this.numCodeURL != '') {
                if(this.clickUpdateIcon == false) {     // 关闭旋转动画
                    this.clickUpdateIcon = true;
                    setTimeout(async () => {
                        this.numCodeURL = await this.onGetCaptcha(stamp);
                        this.clickUpdateIcon = false;
                    }, 700)
                }
            } else {
                this.numCodeURL = await this.onGetCaptcha(stamp);
            }
        },
    },
    created(){
    },
    mounted(){
        this.updateValidateCode()    // 页面加载的时候初始化数字验证码
    }
  }
</script>

<style lang="scss" scoped>
@import "@/styles/mixin.scss";
@include resetCheckbox();  // 清除原生input样式
.input-focus{
    border-bottom-color: var(--inputFocusColor) !important;
}
.message-login {
    display: flex;
    flex-direction: column;
    .bind-account{
        display: flex;
        flex-direction: column;
        .message-login-pwd {
            margin-bottom: 0 !important;
        }
    }
    .general-message-login{
        display: flex;
        flex-direction: column;
    }
    .message-input{   // 短信登录的输入框
        box-sizing: border-box;
        height: 38px;
        border-bottom: 2px solid #F2F3F5;
        margin-bottom: 32px;
    }
    .message-input:nth-child(1){
        margin-top: 24px;
    }
    .verify-code-container {
        border-bottom: 2px solid #F2F3F5;
        margin-bottom: 0; 
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 32px;
        .general-verify-code{  // 验证码
            box-sizing: border-box;
            height: 38px;
            border: 0px;
            // background-color: antiquewhite;
            flex: 1;
            // border-bottom: 2px solid #F2F3F5;
            // margin-bottom: 24px;
        }
        .get-verify-code {   // 获取验证码的文字
            width: 70px;
            height: 22px;
            color: #FF6A00;
            font-size: 14px;
            cursor: pointer;
            font-weight: 500;
            margin-top: 2px;
            margin-right: 8px;
        }
        .code-img{  // 验证码图标
            width: 48px;
            height: 17px;
            margin-right: 8px;
            margin-top: 2px;
            margin-left: auto;
        }
        .update-code{
            margin-top: 2px;
            width: 118px;
            height: 22px;
            font-size: 14px;
            color: #A9AEB8;
            margin-right: 8px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .countdown{
                width: 17px;
                text-align: right;
            }
        }
    }
    .number-code-container {
        border-bottom: 2px solid #F2F3F5;
        margin-bottom: 24px; 
        display: flex;
        align-items: center;
        justify-content: space-between;
        .code-img{
            width: 100px;
            height: 40px;
        }
        .general-verify-code{  // 验证码
            box-sizing: border-box;
            height: 38px;
            border: 0px;
            flex: 1;   
        }
        .icon-container{
            box-sizing: border-box;
            height: 40px;
            display: flex;
            align-items: center;
            cursor: pointer;
            .update-code-icon{
                width: 18px;
                height: 18px;
                margin-right: 16px;
                margin-top: 5px;
            }
            .number-animation{   // 点击刷新二维码的图标时旋转
                animation: loading 0.7s ease-in-out infinite forwards;
            }
            @keyframes loading {  // 旋转动画
                0% {
                    transform: rotate(0deg);
                }
                100% {
                    transform: rotate(-360deg);
                }
            }
        }
    }
    .tip {  // 提示语
        font-size: 13px;
        color: #FF3B30;
        font-weight: 400;
        margin-top: 16px;
    }
}
.login-btn{  // 登录
    margin-top: 32px;
    width: 392px;
    height: 40px;
    background-color: #ff6a00;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
    .login-text {
        // width: 38px;
        height: 22px;
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 500;
    }
}

</style>