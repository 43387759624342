<template>
<!-- 统一登录页面的svg格式logo集合 -->

<!-- svg内嵌html填坑指南 -->
<!-- 1.必须要用v-if 因为一个页面如果出现多个svg会互相影响  详情见：https://juejin.cn/post/6844903876210999303 -->
<!-- 2.svg默认是display:inline-block 想要实现垂直居中最好改成block -->
<div>
    <!-- 普通登录logo -->
    <div v-if="iconName == 'PCLogin'">
        <svg width="56px" height="56px" viewBox="0 0 56 56" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <defs>
                <path d="M0,0 L48,0 C52.418278,2.22441625e-15 56,3.581722 56,8 L56,56 L56,56 L28,28 L0,0 Z" id="PCLoginPath"></path>
            </defs>
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g>
                    <mask id="mask-PCLogin" fill="white">
                        <use xlink:href="#PCLoginPath"></use>
                    </mask>
                    <!-- <use fill-opacity="0.1" fill="#FF6A00" xlink:href="#PCLoginPath"></use> -->
                    <use :fill="QRCodeOrPCBackgroundColor" xlink:href="#PCLoginPath"></use>
                    <g mask="url(#mask-PCLogin)">
                        <g transform="translate(14.000000, 12.000000)">
                            <!-- <rect stroke="#FF6A00" stroke-width="3" fill="none" x="0" y="0" width="30" height="20" rx="1"></rect>
                            <rect stroke="none" fill="#FF6A00" fill-rule="evenodd" x="9" y="9" width="13" height="3" rx="1.5"></rect>
                            <rect stroke="none" fill="#FF6A00" fill-rule="evenodd" x="2" y="27" width="29" height="3" rx="1.5"></rect>
                            <polygon stroke="none" fill="#FF6A00" fill-rule="evenodd" transform="translate(22.500000, 26.000000) rotate(-270.000000) translate(-22.500000, -26.000000) " points="17.5 24.5 27.5 24.5 27.5 27.5 17.5 27.5"></polygon>
                            <polygon stroke="none" fill="#FF6A00" fill-rule="evenodd" transform="translate(9.000000, 26.000000) rotate(-270.000000) translate(-9.000000, -26.000000) " points="4 24 14 24 14 28 4 28"></polygon> -->
                            <rect :stroke="QRCodeOrPC" stroke-width="3" fill="none" x="0" y="0" width="30" height="20" rx="1"></rect>
                            <rect stroke="none" :fill="QRCodeOrPC" fill-rule="evenodd" x="9" y="9" width="13" height="3" rx="1.5"></rect>
                            <rect stroke="none" :fill="QRCodeOrPC" fill-rule="evenodd" x="2" y="27" width="29" height="3" rx="1.5"></rect>
                            <polygon stroke="none" :fill="QRCodeOrPC" fill-rule="evenodd" transform="translate(22.500000, 26.000000) rotate(-270.000000) translate(-22.500000, -26.000000) " points="17.5 24.5 27.5 24.5 27.5 27.5 17.5 27.5"></polygon>
                            <polygon stroke="none" :fill="QRCodeOrPC" fill-rule="evenodd" transform="translate(9.000000, 26.000000) rotate(-270.000000) translate(-9.000000, -26.000000) " points="4 24 14 24 14 28 4 28"></polygon>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    </div>
    <!-- 扫码登录logo -->
    <div v-if="iconName == 'QRCode'">
        <svg width="56px" height="56px" viewBox="0 0 56 56" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <defs>
                <path d="M0,0 L48,0 C52.418278,2.22441625e-15 56,3.581722 56,8 L56,56 L56,56 L28,28 L0,0 Z" id="QRCodePath"></path>
            </defs>
            <g id="ScanQRCode" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g>
                    <mask id="mask-QRCode" fill="white">
                        <use xlink:href="#QRCodePath"></use>
                    </mask>
                    <!-- <use fill-opacity="0.1" fill="#FF6A00" xlink:href="#QRCodePath"></use> -->
                    <use :fill="QRCodeOrPCBackgroundColor" xlink:href="#QRCodePath"></use>
                    <g mask="url(#mask-QRCode)">
                        <g transform="translate(11.000000, 12.000000)">
                            <rect :stroke="QRCodeOrPC" stroke-width="2.85" fill="none" x="0" y="0" width="16.8" height="17" rx="0.95"></rect>
                            <rect :stroke="QRCodeOrPC" stroke-width="2.85" fill="none" x="23.3333333" y="0" width="10.2666667" height="10.3888889" rx="0.95"></rect>
                            <rect :stroke="QRCodeOrPC" stroke-width="2.85" fill="none" x="24.2666667" y="24.5555556" width="9.33333333" height="9.44444444" rx="0.95"></rect>
                            <rect stroke="none" :fill="QRCodeOrPC" fill-rule="evenodd" x="5.6" y="5.66666667" width="5.6" height="5.66666667" rx="0.95"></rect>
                            <rect stroke="none" :fill="QRCodeOrPC" fill-rule="evenodd" x="22.4" y="15.1111111" width="3.73333333" height="3.77777778" rx="0.95"></rect>
                            <rect stroke="none" :fill="QRCodeOrPC" fill-rule="evenodd" x="28.4666667" y="15.1111111" width="6.53333333" height="3.77777778" rx="0.95"></rect>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    </div>
    <!-- 账号登录里的记住密码和注册页面同意协议左侧勾选框：未选中样式-->
    <div v-if="iconName == 'checkbox-unchecked'">
        <svg class="svg-block" width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="单选框/未选中" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g fill="#FFFFFF" id="矩形" stroke="#E5E6EB">
                    <rect x="0.5" y="0.5" width="15" height="15" rx="2"></rect>
                </g>
            </g>
        </svg>
    </div>


    <!-- 账号登录里的记住密码和注册页面同意协议左侧勾选框：选中样式-->
    <div v-if="iconName == 'checkbox-checked'">
        <svg class="svg-block" width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="单选框/选中" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g>
                    <!-- <rect id="矩形" fill="#FF6A00" x="0" y="0" width="16" height="16" rx="2"></rect> -->
                    <rect id="矩形" :fill="checkboxCheckedBgColor" x="0" y="0" width="16" height="16" rx="2"></rect>
                    <g id="编组" transform="translate(3.000000, 3.000000)">
                        <rect id="矩形" fill-opacity="0.01" fill="#FFFFFF" fill-rule="nonzero" x="0" y="0" width="10" height="10"></rect>
                        <polyline id="路径" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" points="8.95833333 2.29166667 3.515625 7.70833333 1.04166667 5.24620833"></polyline>
                    </g>
                </g>
            </g>
        </svg>
    </div>

    <!-- 导航栏选中时下面的渐变图标 -->
    <div v-if="iconName == 'tab-icon'">
        <svg class="svg-block" width="34px" height="10px" viewBox="0 0 34 10" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <defs>
                <linearGradient x1="-28.0619734%" y1="33.9290466%" x2="100%" y2="33.9290466%" id="linearGradient-1">
                    <stop :stop-color="tabIconStartColor" offset="0%"></stop>
                    <stop :stop-color="tabIconEndColor" offset="100%"></stop>
                    <!-- <stop stop-color="#FF6A00" offset="0%"></stop>
                    <stop stop-color="#FFC500" offset="100%"></stop> -->
                </linearGradient>
            </defs>
            <g id="控件" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round">
                <g id="登录标题/选中备份-3" transform="translate(-15.000000, -28.000000)" stroke="url(#linearGradient-1)" stroke-width="3">
                    <g id="tab_选中标" transform="translate(15.000000, 28.000000)">
                        <path d="M3,4 C7.03305212,6.66666667 11.6997188,8 17,8 C22.3002812,8 26.9669479,6.66666667 31,4" id="路径-3"></path>
                    </g>
                </g>
            </g>
        </svg>
    </div>

    <!-- 普通注册页面导航栏选中时下面的渐变图标 -->
    <div v-if="iconName == 'common-tab-icon'">
        <svg class="svg-block" width="34px" height="10px" viewBox="0 0 34 10" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <defs>
                <linearGradient x1="-28.0619734%" y1="33.9290466%" x2="100%" y2="33.9290466%" id="common-linearGradient-1">
                    <stop :stop-color="tabIconStartColor" offset="0%"></stop>
                    <stop :stop-color="tabIconEndColor" offset="100%"></stop>
                    <!-- <stop stop-color="#FF6A00" offset="0%"></stop>
                    <stop stop-color="#FFC500" offset="100%"></stop> -->
                </linearGradient>
            </defs>
            <g id="common-控件" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round">
                <g id="common-登录标题/选中备份-3" transform="translate(-15.000000, -28.000000)" stroke="url(#common-linearGradient-1)" stroke-width="3">
                    <g id="common-tab_选中标" transform="translate(15.000000, 28.000000)">
                        <path d="M3,4 C7.03305212,6.66666667 11.6997188,8 17,8 C22.3002812,8 26.9669479,6.66666667 31,4" id="common-路径-3"></path>
                    </g>
                </g>
            </g>
        </svg>
    </div>

    <!-- 联想注册页面导航栏选中时下面的渐变图标 -->
    <div v-if="iconName == 'lenovo-tab-icon'">
        <svg class="svg-block" width="34px" height="10px" viewBox="0 0 34 10" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <defs>
                <linearGradient x1="-28.0619734%" y1="33.9290466%" x2="100%" y2="33.9290466%" id="lenovo-linearGradient-1">
                    <stop :stop-color="tabIconStartColor" offset="0%"></stop>
                    <stop :stop-color="tabIconEndColor" offset="100%"></stop>
                    <!-- <stop stop-color="#FF6A00" offset="0%"></stop>
                    <stop stop-color="#FFC500" offset="100%"></stop> -->
                </linearGradient>
            </defs>
            <g id="lenovo-控件" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round">
                <g id="lenovo-登录标题/选中备份-3" transform="translate(-15.000000, -28.000000)" stroke="url(#lenovo-linearGradient-1)" stroke-width="3">
                    <g id="lenovo-tab_选中标" transform="translate(15.000000, 28.000000)">
                        <path d="M3,4 C7.03305212,6.66666667 11.6997188,8 17,8 C22.3002812,8 26.9669479,6.66666667 31,4" id="lenovo-路径-3"></path>
                    </g>
                </g>
            </g>
        </svg>
    </div>

    <!-- 忘记密码导航栏选中时下面的渐变图标 -->
    <div v-if="iconName == 'forget-tab-icon'">
        <svg class="svg-block" width="34px" height="10px" viewBox="0 0 34 10" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <defs>
                <linearGradient x1="-28.0619734%" y1="33.9290466%" x2="100%" y2="33.9290466%" id="lenovo-linearGradient-1">
                    <stop :stop-color="tabIconStartColor" offset="0%"></stop>
                    <stop :stop-color="tabIconEndColor" offset="100%"></stop>
                    <!-- <stop stop-color="#FF6A00" offset="0%"></stop>
                    <stop stop-color="#FFC500" offset="100%"></stop> -->
                </linearGradient>
            </defs>
            <g id="lenovo-控件" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round">
                <g id="lenovo-登录标题/选中备份-3" transform="translate(-15.000000, -28.000000)" stroke="url(#lenovo-linearGradient-1)" stroke-width="3">
                    <g id="lenovo-tab_选中标" transform="translate(15.000000, 28.000000)">
                        <path d="M3,4 C7.03305212,6.66666667 11.6997188,8 17,8 C22.3002812,8 26.9669479,6.66666667 31,4" id="lenovo-路径-3"></path>
                    </g>
                </g>
            </g>
        </svg>
    </div>

    <!-- 忘记密码和立即注册右侧登录文字右侧的 > 图标 -->
    <div v-if="iconName == 'back-icon'">
        <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <title>icon_返回登录@2x</title>
            <g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="icon_返回登录" transform="translate(10.000000, 10.000000) scale(-1, 1) translate(-10.000000, -10.000000) ">
                    <rect id="矩形" fill-opacity="0.01" fill="#FFFFFF" fill-rule="nonzero" x="0" y="0" width="20" height="20"></rect>
                    <polyline id="路径" :stroke="backToLoginIcon" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" points="12.9166667 15 7.91666667 10 12.9166667 5"></polyline>
                    <!-- <polyline id="路径" stroke="#FF6A00" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" points="12.9166667 15 7.91666667 10 12.9166667 5"></polyline> -->
                </g>
            </g>
        </svg>
    </div>

</div>
</template>
<script>
export default {
    props:['iconName','config','previewConfig','Mode'],
    data() {
        return {

        }
    },
    computed:{
        QRCodeOrPC(){                        // 二维码或者PC图标的颜色
            if(this.Mode == 'preview'){  // 处于预览模式
                return this.previewConfig.colorConfig.qRcodeOrAccountIcon.icon
            }else{                           // 正式模式
                return this.config.colorConfig.qRcodeOrAccountIcon.icon
            }
        },
        QRCodeOrPCBackgroundColor(){         // 二维码或者PC图标背景的颜色
            if(this.Mode == 'preview'){  
                return this.previewConfig.colorConfig.qRcodeOrAccountIcon.backgroundColor
            }else{                          
                return this.config.colorConfig.qRcodeOrAccountIcon.backgroundColor
            }
        },
        tabIconStartColor() {   // 导航选中时下面的弧形渐变图标左侧颜色
            if(this.Mode == 'preview'){  
                return this.previewConfig.colorConfig.loginModeSelect.startColor
            }else{                       
                return this.config.colorConfig.loginModeSelect.startColor
            }
        },
        tabIconEndColor() {   // 导航选中时下面的弧形渐变图标右侧颜色
            if(this.Mode == 'preview'){  
                return this.previewConfig.colorConfig.loginModeSelect.endColor
            }else{                       
                return this.config.colorConfig.loginModeSelect.endColor
            }
        },
        backToLoginIcon() {  // 忘记密码和立即注册右侧登录文字右侧的 > 图标
            if(this.Mode == 'preview'){  
                return this.previewConfig.colorConfig.otherText
            }else{                       
                return this.config.colorConfig.otherText
            }
        },
        checkboxCheckedBgColor() {  // 勾选框被选中时的背景色
            if(this.Mode == 'preview'){  
                return this.previewConfig.colorConfig.rememberPassword
            }else{                       
                return this.config.colorConfig.rememberPassword
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.svg-block{
    display: block;
}

</style>

