<template>
<div class="forget-pwd" :style="{'--inputFocusColor':inputFocusBottomColor}">
    <div class="title">
        <!-- 导航区域 -->
        <div class="navigation">
            <div :class="{'nav-pane':true,  'nav-selected':item.title == currentTag}" @click="clickNav(item.title)" v-for="item in nav" :key="item.id">
                <div class="nav-pane-title">{{item.title}}</div>
                <svg-icon class="nav-pane-icon" iconName="lenovo-tab-icon" v-if="item.title == currentTag" :config="config" :previewConfig="previewConfig" :Mode="Mode"></svg-icon>
            </div>
        </div>
        <div class="title-right" @click="switchToLogin('click')">
            <div :style="{'color':`${specialTextColor} !important`}">{{ isClient ? '去关联' : '登录' }}</div>
            <svg-icon iconName="back-icon" :previewConfig="previewConfig" :config="config" :Mode="Mode"></svg-icon>
        </div>
    </div>
    <!-- 表单填写区域 -->
    <div class="form">
        <input
        type="text"
        @click="inputSelected = '手机'"
        @blur="inputBlur"
        v-model="phoneNum"
        :class="{'clear-input':true, 'general-input':true, 'input-focus':inputSelected == '手机'}" 
        spellcheck="false"
        placeholder="手机">
        <div class="verify-code-container">
            <input  
            type="text" 
            v-model="numCode"
            class="clear-input verify-code" 
            spellcheck="false" 
            placeholder="图形验证码">
            <div class="icon-container" @click="updateValidateCode">
                <img :src="numCodeURL" class="code-img" alt="">
                <img v-cdnimg src="/Account/images/signin/updateIcon.png" :class="{'update-code-icon':true, 'update-animation':clickUpdateIcon}" alt="">
            </div>
        </div>
        <div
        @click="inputSelected = '验证码'"
        :class="{'verify-code-container':true, 'input-focus':inputSelected == '验证码'}">
            <input 
            v-model="verifyCode" 
            type="text" 
            placeholder="短信验证码" 
            @blur="inputBlur" 
            class="clear-input" 
            spellcheck="false">
            <div class="get-verify-code" v-show="showText" @click="getVerifyCode" :style="{'color':`${specialTextColor} !important`}">
                获取验证码
            </div>
            <div v-show="!showText" :class="{'update-code':true, 'update-animation':clickUpdate}" alt="">
                <div class="countdown">{{countdown}}</div>
                <div class="countdown-text"> 秒后可重新发送</div>
            </div>
        </div>
        <div
        @click="inputSelected = '密码'" 
        :class="{'pwd-input-container':true, 'input-focus':inputSelected == '密码'}">
            <input v-model="password" :type="pwdInputType" placeholder="密码" @blur="inputBlur" class="clear-input lenovo-pwd-input" spellcheck="false">
            <div class="pwd-show-status-icon" @click="changePwdShowStatus">
                <!-- 密码显隐 -->
                <img v-cdnimg class="pwd-status-icon" v-show="pwdStatus == 'show'" src="/Account/images/signin/showPwd.png" alt="">
                <img v-cdnimg class="pwd-status-icon" v-show="pwdStatus == 'hidden'" src="/Account/images/signin/hidePwd.png" alt="">
            </div>
        </div>

        <!-- <input
        v-show="currentTag == '教师'" 
        type="text"
        @click="inputSelected = '学校名称'" 
        v-model="schoolName"
        @blur="inputBlur"
        :class="{'clear-input':true, 'general-input':true, 'input-focus':inputSelected == '学校名称','school-name':true}" 
        spellcheck="false" 
        placeholder="学校名称"> -->

        <!-- <div class="teacher-proof-container" v-show="currentTag == '教师'">
            <el-popover
                placement="top-start"
                width="14"
                class="help-icon-popover"
                trigger="click"
                content="请上传教师资格证照片">
                <div slot="reference">
                    <img v-cdnimg src="/Account/images/signin/help.png" class="help-icon" alt="">
                </div>
            </el-popover>
            <input v-model="verifyCode" type="text" placeholder="教师证明" readonly="readonly" class="clear-input teacher-proof-file" spellcheck="false">
            <div class="upload-img" :style="{'color':`${specialTextColor} !important`}">
                上传图片
            </div>
        </div>
        <div class="upload-teacher-proof-tip" v-show="currentTag == '教师'">
            只能上传jpg/png/jpeg格式文件，文件大小不能超过5M
        </div> -->
        <!-- <input 
        v-show="currentTag == '家长'" 
        @click="inputSelected ='班级号'"
        v-model="classNum"
        @blur="inputBlur"
        type="password" 
        :class="{
            'clear-input':true,
            'general-input':true, 
            'class-id':true,
            'input-focus':inputSelected == '班级号'
        }" 
        spellcheck="false" 
        placeholder="班级号"> -->

    </div>
    <!-- 服务条款 -->
    <div class="clause" v-show="false">
        <div @click="clickCheckbox" class="clause-checkbox">
            <!-- 原来的方案是在原生input框上覆盖一个自己画的选中框 -->
            <!-- 现在的方案是改用svg嵌在html中 -->
            <svg-icon iconName="checkbox-unchecked" v-show="isAgree == false" :previewConfig="previewConfig" :config="config" :Mode="Mode"></svg-icon>
            <svg-icon iconName="checkbox-checked" v-show="isAgree == true" :previewConfig="previewConfig" :config="config" :Mode="Mode"></svg-icon>
        </div>
        <div class="clause-text">
            <span @click="clickCheckbox" class="clause-left-text">我已阅读并同意</span>
            <span class="clause-content" @click="openServicePolicyDialog">服务条款</span>
        </div>
    </div>
    <!-- 提交按钮 -->
    <div class="signup-btn" @click="clickSignupBtn" :style="{'background-color':submitBtnBackgroundColor}">
        <div class="signup-text">立即注册</div>
    </div>
    <!-- 注册时的服务条款 -->
    <service-policy name="联想" :showDialog="showDialog" @closeServicePolicyDialog="closeServicePolicyDialog"></service-policy>
</div>
</template>

<script>
import { 
    lENOVO_TEACHER_REGISTER, 
    NEW_SHORT_MESSAGE_SEND, 
    NEW_lENOVO_TEACHER_REGISTER 
} from '@/http/api.js'
import SvgIcon from './SvgIcon.vue'
import ServicePolicy from './ServicePolicy.vue'
  export default {
    name: "ForgetPwd",
    components: { SvgIcon, ServicePolicy },
    props: ['config','previewConfig','Mode','bindEnterFlag', 'isClient'],
    data() {
      return {
        phoneNum:'',       // 手机号
        password:'',       // 密码
        institutionId:'',  // 通过扫描二维码获取到的机构编号
        monthNum:36,        // 账号使用期限（月）现在默认36，以后可能会改
        schoolName:'',     // 学校名称
        classNum:'',        // 班级号
        email:'',         // 邮箱
        verifyCode:'',    // 验证码
        clickUpdateIcon:false,  // 是否点击刷新验证码图标了
        nav:[   // 导航区域
            {title:'教师', flag:false, id:0},
            // {title:'家长', flag:false, id:1},
        ],
        currentTag:'教师',    // 保存当前所点击的导航
        isAgree:true,        // 是否同意条款
        showText:true,        // 显示获取验证码
        clickUpdate:false,    // 是否点击了更新验证码 
        countdown:60,         // 倒计时
        pwdStatus:'hidden',    // 密码框是否显示
        pwdInputType:'password',   // 控制密码输入框显隐
        inputSelected:'',      // 获取焦点的input框
        showDialog:false,      // 注册时的合同条款
        numCodeURL:'',         // 图形验证码地址
        numCode:'',            // 图形验证码输入的内容
      }
    },
    watch:{
        bindEnterFlag:{
            handler:function(newVal, oldVal){
                let that = this
                if(newVal == '联想注册'){
                    document.onkeydown = function(event){
                        if(event.key == 'Enter'){
                            that.clickSignupBtn() 
                        }
                    }
                    this.updateValidateCode();
                    this.showText = true;
                    this.phoneNum = '';
                    this.verifyCode = '';
                    this.password = '';
                }
            },
            immediate: true
        }
    },
    computed:{
        specialTextColor(){     // 登录 > 的颜色
            if(this.Mode == 'preview'){    // 预览模式
                return this.previewConfig.colorConfig.otherText
            }else{                       
                return this.config.colorConfig.otherText
            }
        },
        inputFocusBottomColor() {   // 点击输入框时输入框底部的颜色
            if(this.Mode == 'preview'){    // 预览模式
                return this.previewConfig.colorConfig.inputBoxSelect
            }else{                       
                return this.config.colorConfig.inputBoxSelect
            }
        },
        submitBtnBackgroundColor(){   // 立即注册按钮的背景色
            if(this.Mode == 'preview'){    // 预览模式
                return this.previewConfig.colorConfig.loginButton
            }else{                       
                return this.config.colorConfig.loginButton
            }
        }
    },
    methods: {
        sendMessage(){   // 发送短信
            let param = {
                Number:this.phoneNum,
                ImageVerificoationCode:this.numCode,
                stamp:this.getSessionVal('stamp'),
                signname:"联想",
                SendType: 2
            }
            NEW_SHORT_MESSAGE_SEND(param).then((res)=>{
                let returnCode = res.data.ReturnCode
                if(returnCode == '1'){
                    this.showText = false   // 显示倒计时
                    this.countdown = 59     // 每次点击打开时重置为59
                    let timer = setInterval(()=>{
                        this.countdown--
                        if(this.countdown <= 0){
                            clearInterval(timer)
                            this.showText = true
                        }
                    }, 1000)
                    this.$message.success('发送成功!');
                }else if(returnCode == '-109001'){
                    this.$message.error(res.data.ReturnText);
                    this.updateValidateCode();  // 更新验证码 
                }else{
                    this.$message.error('发送短信验证码失败!');
                    this.updateValidateCode();  // 更新验证码 
                }
            })
        },
        openServicePolicyDialog(){
            this.showDialog = true  // 打开合同条款
        },
        switchToLogin(type){   // 切换到登录页面
            if(this.isClient && type == 'success') {
                this.$bus.$emit('clientSwitchToLogin', JSON.stringify({ userName: this.phoneNum, passWord: this.password }));
            }
            this.$emit("switchToLogin")
        },
        clickNav(name){    // 点击
            this.currentTag = name     // 确定当前点击的是哪个标签页
            this.phoneNum = ''       // 切换时数据置空
            this.password = ''     
            this.schoolName = ''
            this.classNum = ''    // 班级号置空
        },
        clickCheckbox(){  // 点击我已却独并同意服务条款
            this.isAgree = !this.isAgree
        },
        getVerifyCode(){   // 点击获取短信验证码
            if(this.phoneNum == ''){
                this.$message.error('请输入手机号');
                this.updateValidateCode()  // 更新验证码 
            }else if(this.numCode == ''){
                this.$message.error('请输入图形验证码');
                this.updateValidateCode()  // 更新验证码 
            }else{
                var reg = /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/   // 手机号正则
                if(reg.test(this.phoneNum)){
                    this.sendMessage()    // 向手机发送验证码
                }else{
                    this.$message.error('请输入正确格式的手机号码');
                    this.updateValidateCode()  // 更新验证码 
                }
            }
        },
        changePwdShowStatus() {  // 更改密码框显示和隐藏状态
            if(this.pwdStatus == 'show'){   // 原来是显示图标
                this.pwdStatus = 'hidden'
                this.pwdInputType = 'password'
            }else{
                this.pwdStatus = 'show'
                this.pwdInputType = 'text'
            }
        },
        inputBlur() {   // 输入框失去焦点的时候不显示下划线颜色
            this.inputSelected = ''
        },
        clickSignupBtn() {  // 点击立即注册按钮
            if(this.phoneNum == ''){
                this.$message.error('请输入手机号');
            }else if(this.password == ''){
                this.$message.error('请输入密码');
            }else if(this.verifyCode == ''){
                this.$message.error('请输入短信验证码');
            }else if(this.isAgree == false){
                this.$message.error('请同意服务条款');
            }else{
                if(!this.checkPassword(this.password) || this.password.length < 8){
                    this.$message.error('大写字母、小写字母、数字、特殊符号至少包含3种，长度≥8位');
                    return
                } else{  // 密码符合要求
                    let param = {
                        account: this.phoneNum,
                        pwd: this.password,
                        isPhone: true,
                        hostName: location.hostname,
                        institutionNo: this.institutionId,   // 通过扫描二维码获取到的机构编号，没有先传空
                        institutionName: this.schoolName,     // 一种是输入的学校名称，另一种是通过扫描二维码获取到的机构名称
                        monthNum: this.monthNum,             // 账号使用期限（月），现在默认36，以后可能会改
                        verificoationCode: this.verifyCode       // 手机验证码
                    }
                    NEW_lENOVO_TEACHER_REGISTER(this.$qs.stringify(param)).then((res) => {
                        let result = res.data.result;
                        let returnText = '';
                        if(this.isValid(res.data.ReturnText)){
                            returnText = res.data.ReturnText.split(' ')[1];
                        }
                        if(res.data.ReturnCode == 1 && this.isValidArray(result)){
                            this.$message.success('注册成功!');
                            this.switchToLogin('success');    // 注册成功的时候切换到登录页面
                        }else{
                            this.$message.error(returnText);
                            this.updateValidateCode();
                        }
                    })
                }
            }
        },
        closeServicePolicyDialog() { // 关闭注册服务条款弹窗
            this.showDialog = false
        },
        async updateValidateCode() {  // 更新验证码 
            this.numCode = '';    //刷新验证码时把原来的验证码置空
            let stamp = this.getSessionVal('stamp');
            if(this.numCodeURL != '') {
                if(this.clickUpdateIcon == false) {     // 关闭旋转动画
                    this.clickUpdateIcon = true;
                    setTimeout(async () => {
                        this.numCodeURL =await this.onGetCaptcha(stamp);
                        this.clickUpdateIcon = false;
                    }, 700)
                }
            } else {
                this.numCodeURL = await this.onGetCaptcha(stamp);
            }
        },
    },
    created(){},
    mounted(){
        this.updateValidateCode()    // 页面加载的时候初始化数字验证码
    }
  }
</script>

<style lang="scss" scoped>
@import "@/styles/mixin.scss";
@include resetCheckbox();  // 清除原生input样式
.input-focus{    // input获取焦点时下划线的颜色
    border-bottom-color: var(--inputFocusColor) !important;
}
.forget-pwd{
    width: 100%;
    height: 100px;
    .title {
        font-size: 16px;
        font-weight: 500;
        color: #1D2129;
        padding-bottom: 17px;
        display: flex;
        align-items: center;
        margin-top: -2px;
        justify-content: space-between;
        .navigation {
            height: 38px;
            display: flex;
            .nav-pane{
                width: 32px;
                height: 38px;
                margin-right: 34px;
                font-size: 14px;
                color: #6B7785;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
                .nav-pane-title {
                    height: 24px;
                    display: flex;
                    align-items: flex-end;
                }
                .nav-pane-icon{
                    width: 34px;
                    height: 10px;
                }
            }
            .nav-selected{
                font-size: 16px;
                font-weight: 600;
                color: #1D2129;
            }
        }
        .title-right {
            font-size: 14px;
            font-weight: 500;
            color: #FF6A00;
            cursor: pointer;
            display: flex;
            margin-bottom: 7px;
        }
    }
    .form {   // 普通登录
        display: flex;
        flex-direction: column;
        .first-line{
            display: flex;
            justify-content: space-between;
            .input-name {  // 用户名和姓名
                box-sizing: border-box;
                height: 38px;
                width: 184px;
                border: 0px;
                border-bottom: 2px solid #F2F3F5;
                margin-bottom: 32px;
            }
        }
        .general-input {  // 密码
            box-sizing: border-box;
            height: 38px;
            border: 0px;
            border-bottom: 2px solid #F2F3F5;
            margin-bottom: 16px;
        }
        .school-name{
            margin-bottom: 160px !important;
        }
        .pwd-input-container {   // 密码输入框
            border-bottom: 2px solid #F2F3F5;
            margin-bottom: 106px; 
            display: flex;
            align-items: center;
            justify-content: space-between;
            box-sizing: border-box;
            height: 38px;
            .lenovo-pwd-input {
                flex: 1;
            }
            .pwd-show-status-icon {   // 获取验证码的文字
                width: 20px;
                height: 22px;
                color: #FF6A00;
                font-size: 14px;
                cursor: pointer;
                font-weight: 500;
                margin-top: 2px;
                margin-right: 8px;
                .pwd-status-icon {
                    width: 20px;
                    height: 20px;
                }
            }
        }
        .verify-code-container {
            border-bottom: 2px solid #F2F3F5;
            margin-bottom: 16px; 
            display: flex;
            align-items: center;
            justify-content: space-between;
            box-sizing: border-box;
            height: 38px;
            .get-verify-code {   // 获取验证码的文字
                width: 70px;
                height: 22px;
                color: #FF6A00;
                font-size: 14px;
                cursor: pointer;
                font-weight: 500;
                margin-top: 2px;
                margin-right: 8px;
            }
            .code-img{
                width: 100px;
                height: 40px;
            }
            .update-code{  // 60秒后可重新发送
                margin-top: 2px;
                width: 118px;
                height: 22px;
                line-height: 22px;
                font-size: 14px;
                color: #A9AEB8;
                margin-right: 8px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .countdown{
                    height: 22px;
                    line-height: 22px;
                    width: 17px;
                    text-align: right;
                }
                .countdown-text{
                    height: 22px;
                    line-height: 22px;
                }
            }
            .verify-code{  // 验证码
                box-sizing: border-box;
                height: 38px;
                border: 0px;
                flex: 1;
            }
            .icon-container{
                box-sizing: border-box;
                height: 40px;
                display: flex;
                align-items: center;
                margin-bottom: 2px;
                cursor: pointer;
                .update-code-icon{
                    width: 18px;
                    height: 18px;
                    margin-right: 16px;
                    margin-top: 5px;
                }
                .update-animation{   // 点击刷新二维码的图标时旋转
                    animation: loading 1.2s ease-in-out infinite forwards;
                }
                @keyframes loading {  // 旋转动画
                    0% {
                        transform: rotate(0deg);
                    }
                    100% {
                        transform: rotate(-360deg);
                    }
                }
            }
        }
        .teacher-proof-container {  // 教师证明
            border-bottom: 2px solid #F2F3F5;
            margin-bottom: 8px; 
            display: flex;
            align-items: center;
            justify-content: space-between;
            box-sizing: border-box;
            height: 38px;
            .help-icon-popover{  // 教师证明前面的？图标
                ::v-deep .el-popover__reference{
                    display: flex;
                    align-items: center;
                    margin-top: 3px;
                }
            }
            .teacher-proof-file{
                flex: 1;
                padding-left: 4px;
            }
            .help-icon{
                width: 15px;
                height: 15px;
                cursor: pointer;
                margin-left: 8px;
            }
            .upload-img {   // 上传图片文字
                width: 70px;
                height: 22px;
                color: #FF6A00;
                font-size: 14px;
                cursor: pointer;
                font-weight: 500;
                margin-top: 2px;
                margin-right: 8px;
                text-align: right;
            }
        }
        .upload-teacher-proof-tip {
            font-size: 13px;
            color: #A9AEB8;
            margin-bottom: 24px;
            margin-left: 8px;
            line-height: 20px;
        }
        .class-id{
            margin-bottom: 106px !important;
        }
    }
    .clause{
        display: flex;
        align-items: center;
        .clause-checkbox{
            cursor: pointer;
        }
        .clause-text{
            color: #4E5969;
            font-size: 13px;
            line-height: 21px;
            margin-left: 7px;
            .clause-left-text{
                cursor: pointer;
            }
            .clause-content {
                color: #0099ff;
                cursor: pointer;
            }
        }

        label {
            position: relative;
            cursor: pointer;

            input {
                cursor: pointer;
            }

            input:checked + .show-box {
                background: #ff6a00;
                border: 1px solid white;
                border-radius: 2px;
            }

            .show-box {
                position: absolute;
                top: 0;
                left: 0;
                width: 15px;
                height: 15px;
                border-radius: 2px;
                border: 1px solid #d8d8d8;
                background: white; // 这里取个巧，与下面颜色一样而已

                &:before { // 使用了 absolute 所以无所谓是 before 还是 after
                    content: ''; // 空白内容占位，当做盒模型处理，见下面
                    position: absolute;
                    top: 1px;
                    left: 5.5px;
                    width: 3px; // 勾的短边
                    height: 8px; // 勾的长边
                    border: solid white; // 勾的颜色
                    border-width: 0 2px 2px 0; // 勾的宽度
                    transform: rotate(45deg); // 定制宽高加上旋转可以伪装内部的白色勾
                }
            }
        }
    }
    .signup-btn{  // 提交
        margin-top: 32px;
        width: 392px;
        height: 40px;
        background-color: #ff6a00;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        cursor: pointer;
        .signup-text {
            height: 22px;
            color: #FFFFFF;
            font-size: 16px;
            font-weight: 500;
        }
    }
}
</style>