<template>
<div :style="{'--inputFocusColor':inputFocusBottomColor}">
    <div class="token-login">
        <input 
        v-model="tempToken" 
        type="text" 
        @click="inputSelected = '口令'"
        @blur="inputSelected = ''"
        :class="{'clear-input':true, 'token':true, 'input-focus':inputSelected=='口令'}" 
        spellcheck="false" 
        placeholder="请输入临时口令">
            <!-- 登录按钮 -->
        <div class="login-btn" @click="clickLoginBtn" :style="{'background-color':loginBtnBackgroundColor}">
            <div class="login-text">登&nbsp;录</div>
        </div>
    </div>
</div>
</template>

<script>
import {VALIDATE_TOKEN_LOGIN, GET_ACCOUNT_ROLE} from '@/http/api.js'
  export default {
    name: "TokenLogin",
    props: ['isSecondLogin','config','previewConfig','Mode','bindEnterFlag'],
    data() {
      return {
        tempToken:"",   // 临时口令
        inputSelected:"", // 被选中的输入框名字，来添加不同的底部颜色
      }
    },
    watch:{
        bindEnterFlag:{
            handler:function(newVal, oldVal){
                let that = this
                if(newVal == 'CommandLogin'){
                    document.onkeydown = function(event){
                        if(event.key == 'Enter'){
                            that.clickLoginBtn() 
                        }
                    }
                }
            },
            immediate: true
        }
    },
    computed:{
      loginBtnBackgroundColor(){   // 提交按钮的背景色
          if(this.Mode == 'preview'){    // 预览模式
              return this.previewConfig.colorConfig.loginButton
          }else{                       
              return this.config.colorConfig.loginButton
          }
      },
      inputFocusBottomColor() {   // 点击输入框时输入框底部的颜色
          if(this.Mode == 'preview'){    // 预览模式
              return this.previewConfig.colorConfig.inputBoxSelect
          }else{                       
              return this.config.colorConfig.inputBoxSelect
          }
      }
    },
    methods: {
      selectDiffRole(){   // 登录之后选择不同的角色
          // let param = {
          //     session:this.getSession(),
          //     hostName:location.hostname,
          //     page:0
          // }
          // if (param.hostName == "localhost") {
          //       param.hostName = process.env.VUE_APP_HOSTNAME;
          // }

          let param = this.getAccountRoleParam();
          console.log('accountLogin', param);
          GET_ACCOUNT_ROLE(param).then((res)=>{
              this.$emit('showChooseRole',res)   
          })
      },
      clickLoginBtn(){
        if(this.tempToken == ''){
          this.$message.error('请输入口令');
        }else{
          let param = {
            session:'',
            securitypassword:this.tempToken,
            page:'1'
          }
          VALIDATE_TOKEN_LOGIN(this.$qs.stringify(param)).then(res => {
            let result = res.data;
            if(!this.isValidArray(result.result)) {   // 口令问题
              let text = result.ReturnText;
              let tips = '';
              switch(text) {
                case 'Account expired': tips = '账户已过期!'; break;
                default: tips = text;
              }
              this.$message.error(tips);
            }else{   // 口令验证登录成功
              // 警告： 这里添加口令验证成功之后的跳转和信息保存
              this.$emit('showChooseRole',res.data.result)
            }
          })
        }
      }
    },
    created(){
    },
    mounted(){
    }
  }
</script>

<style lang="scss" scoped>
@import "@/styles/mixin.scss";
@include resetCheckbox();  // 清除原生input样式
.input-focus{
    border-bottom-color: var(--inputFocusColor) !important;
}
.token-login{
    .token{
        width: 100%;
        border-bottom: 2px solid #F2F3F5;
        margin-top: 24px;
        margin-bottom: 32px;
        box-sizing: border-box;
        height: 38px;
    }
    .login-btn{  // 登录
        width: 392px;
        height: 40px;
        background-color: #ff6a00;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        cursor: pointer;
        .login-text {
            // width: 38px;
            height: 22px;
            color: #FFFFFF;
            font-size: 16px;
            font-weight: 500;
        }
    }
}


</style>